import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled, tableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InvoiceServices from 'app/apis/Invoices/InvoiceServices';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import Loader from 'app/components/Loader/Loader';
import StatusChip from 'app/components/StatusChip/StatusChip';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',textAlign:'center',
    color: '#3F424B',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center',
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id: 'invoice_id',label: 'Invoice #',align: 'left',clickAble: true},
  {id: 'customer_name',label: 'Contact',align: 'left',},
  {id: 'bill_date',label: 'Bill Date',align: 'left',},
  {id: 'due_date',label: 'Due Date',align: 'left',},
  {id: 'total_amount',label: 'Total Amount',align: 'left',},
  {id:'status',label:'Status', align:'left'}
];

function Invoices() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [invoiceData, setInvoiceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  const [filter, setFilter] = useState('all');
  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  // *For Getting Service
  const getAllInvoiceDetails = async (obj) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data,count } = await InvoiceServices.getAllInvoiceDetails(obj)
      if (status === true && responseCode === 200) {
        setInvoiceData(data);
        setCount(count);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAllInvoiceDetails({limit:rowsPerPage,page:page,status:filter === 'paid' ? '1' : filter === 'all' ? '' : '0'})
  }, [filter, page, rowsPerPage]);

  return (
    <Layout>
    <Box>
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
      <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>Invoices</Typography>
      </Box>
      <BreadCrumbs currentPage={['Invoices']} />
      {/* Tabs */}
      <Box sx={{border:'1.5px solid #008035', borderRadius:'8px',height:'45px',display:'flex',alignItems:"center",maxWidth:'max-content',boxSizing:'border-box',my:4,}}>
      {['All','Paid','Unpaid'].map((item,index) => (
      <Box sx={{borderRight:index !== 2 ?'1.5px solid #008035' : '',height:'100%',width:'100px',alignItems:'center',cursor:'pointer',
      display:'flex',backgroundColor:filter?.toLowerCase()=== item?.toLowerCase() ? '#008035' : '',borderRadius:index === 0 ? '7px 0px 0px 7px' : index === 2 ? '0px 7px 7px 0px' :  ''}}
      onClick={() => setFilter(item?.toLowerCase())}>
      <Typography sx={{color:filter?.toLowerCase() !== item?.toLowerCase() ? '#008035' : 'white', fontWeight:'bold',mx:'auto'}}>{item}</Typography></Box>
      ))}
    </Box>
    {isLoading ? <Loader /> : 
    <Fragment>
      {invoiceData?.length > 0 ? (
        <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
        <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
          <Table className={classes.table} stickyHeader aria-label="sticky table" >
              <TableHead >
                <TableRow >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ fontWeight:600 }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceData?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <Fragment key={index}>
                          {column?.id === 'status' ? (
                          <StyledTableCell align={column?.align}>
                            <StatusChip value={value === '1' ? 'paid' : 'unpaid'} />
                          </StyledTableCell>
                          ) : column?.id === 'total_amount' ? (
                            <StyledTableCell key={column.id} align={column.align} sx={{cursor: column.clickAble ? 'pointer' : 'default'}}>${value}</StyledTableCell>
                          ) : (
                          <StyledTableCell key={column.id} align={column.align} sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                          onClick={() =>  {column.clickAble && navigate(`${row.id}/${row?.invoice_id}`)}}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>)}
                          </Fragment>
                        );
                      })}
                    </TableRow>
                  );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
          rowsPerPageOptions={[10, 25,50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page-1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Paper>
      ) : (<Box
          display={'flex'}
          p={6.25}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography variant="h5" fontWeight={400} >
            No Data
          </Typography>
        </Box>)}
    </Fragment>}
    </Box>
    </Layout>
  )
}

export default Invoices