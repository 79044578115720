import { get } from 'app/apis';
import InvoiceRoutes from './Invoice.Routes';

const InvoiceServices = {
  getInvoiceById: async (id) => {
    const data = await get(InvoiceRoutes.getInvoiceById+`${id}`);
    return data;
  },
  getAllInvoiceDetails: async (obj) => {
    const data = await get(InvoiceRoutes.getAllInvoiceDetails+ `?limit=${obj?.limit}&page=${obj?.page}${obj?.status ? `&status=${obj?.status}` : ''}`);
    return data;
  },
}

export default InvoiceServices