import React, { Fragment } from "react";
import { Box } from "@mui/material";

// *Import Components
import Header from "./Header";
import SideNav from "./SideNav";
import ScrollTop from "app/hooks/scrollTop";
import colors from "app/styles/colors";

function Layout({ children }) {
  return (
    <Fragment >
      <Box sx={{ display: "flex",backgroundColor:'#ECF0FA' ,p:3, boxSizing:'border-box', minHeight:'100vh', width: {xs:'fit-content',md:'100%'}}}>
        {/* ========== SideNav ========== */}
        <SideNav />
        <Box flex={1} width={{xs:'602px', md:'100%'}} >
        {/* ========== Header ========== */}
          <Header />
          <Box sx={{mt:4}}>
            {children}
          </Box>
        </Box>
      </Box>

      {/* ========== Scroll Top ========== */}
      {/* <ScrollTop /> */}

    </Fragment>
  );
}

export default Layout;
