import React, { Fragment, useEffect, useState } from 'react'
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Box, Typography, Grid,styled, tableCellClasses, Paper } from '@mui/material';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { ApartmentTwoTone,CalendarTodayTwoTone,DateRangeTwoTone,LocalShippingTwoTone, } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { makeStyles } from '@mui/styles';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import StatusChip from 'app/components/StatusChip/StatusChip';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import InvoiceServices from 'app/apis/Invoices/InvoiceServices';
import Loader from 'app/components/Loader/Loader';
import moment from 'moment';
import Images from 'app/assets/Images';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#444444',textAlign:'center',
    color: 'white',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center',
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderLeft: '1px solid #00000033',
      borderRight: '1px solid #00000033',
      borderTop: '1px solid #00000033',
    }
  }
});

const customBoxStyle = {
  boxShadow: `rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;`, 
  minHeight:'150px', borderRadius:'10px',p:3,display:'table-cell',
}

function SingleInvoice() {
  const classes = useStyles();
  const {id,invoiceNumber} = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState('');

  // *For Getting Invoice details
  const getInvoiceById = async (id) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data } = await InvoiceServices.getInvoiceById(id)
      if (status === true && responseCode === 200) {
        setInvoiceDetails(data);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getInvoiceById(id)
  }, [id]);

  return (
    <Layout>
      <Fragment>
      <Box sx={{display:'flex', alignItems:'center'}}>
        <ArrowBackButton />
        <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>Invoice Detail # {invoiceNumber}</Typography>
      </Box>
      <Box sx={{ml:8}}>
        <BreadCrumbs currentPage={['Invoices','Invoice Details']} />
      </Box>
      {isLoading ? <Loader /> :
        <Box sx={{borderRadius:'5px', boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',p:3, backgroundColor:colors.white}}>
          {/* Header */}
          <Box sx={{display:'flex',justifyContent:'space-between', alignItems:'flex-start',mb:2}}>
            <Box>
              <Box sx={{display:'flex',justifyContent:'space-between',gap:2, alignItems:'flex-start',mb:2}}>
                <img src={Images.providerLogo} alt="Provider"
                style={{width:'80px',height:'80px'}} />
                <Box>
                  <Typography variant='h5'>Green Clean Company</Typography>
                  <Typography variant='h5'>شركة النظافة الخضراء</Typography>
                </Box>
              </Box>
              <Typography >VAT-301270264100003</Typography>
              <Typography >Jeddah, Saudi Arabia</Typography>
              <Typography >concierge@greenclean.com.sa</Typography>
            </Box>
            <Box>
              <Typography variant='h5' fontWeight={600}>Tax Invoice</Typography>
              <Typography variant='h5' fontWeight={600}>فاتورة ضريبية</Typography>
            </Box>
          </Box>
          {/* Details */}
          <Box sx={{display:'flex',justifyContent:'space-between', alignItems:'flex-start'}}>
            <Box>
              <Typography fontWeight={600} >Bill To:</Typography>
              <Typography textTransform={'capitalize'}>{invoiceDetails?.customer_name}</Typography>
              <Typography sx={{textTransform:'capitalize'}}>{invoiceDetails?.street}{' , '}{invoiceDetails?.building}</Typography>
              <Typography sx={{textTransform:'capitalize'}}>{invoiceDetails?.city_name}{' , '}{invoiceDetails?.country_name}</Typography>
            </Box>
            <Box>
              <Box sx={{display:'flex',justifyContent:'space-between', alignItems:'flex-start'}}>
                <Box minWidth={'100px'}>
                  <Typography fontWeight={600}>Invoice # :</Typography>
                  <Typography fontWeight={600}># رقم الفاتورة</Typography>
                </Box>
                <Typography minWidth={'100px'} textAlign={'right'}>{invoiceNumber}</Typography>
              </Box>
              <Box sx={{display:'flex',justifyContent:'space-between', alignItems:'flex-start'}}>
                <Box minWidth={'100px'}>
                  <Typography fontWeight={600}>Invoice Date :</Typography>
                  <Typography fontWeight={600}>تاريخ الفاتورة</Typography>
                </Box>
                <Typography minWidth={'100px'} textAlign={'right'}>{moment(invoiceDetails?.bill_date).format("DD-MMM-yyyy")}</Typography>
              </Box>
              <Box sx={{display:'flex',justifyContent:'space-between', alignItems:'flex-start'}}>
                <Box minWidth={'100px'}>
                  <Typography fontWeight={600}>Due Date :</Typography>
                  <Typography fontWeight={600}>تاريخ الاستحقاق</Typography>
                </Box>
                <Typography minWidth={'100px'} textAlign={'right'}>{moment(invoiceDetails?.due_date).format("DD-MMM-yyyy")}</Typography>
              </Box>
            </Box>
          </Box>
          {/* Table For Products */}
          <Box>
            {invoiceDetails?.jobOrderServices?.length > 0 && 
            <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',my:3  }}>
              <TableContainer sx={{ maxHeight: 800}}>
                <Table className={classes.table} stickyHeader aria-label="sticky table" >
                  <TableHead >
                    <TableRow style={{p:0}}>
                      {[{name:'#'},{name:'Description',trans:`وصف`,align:'left'},{name:'Qty',trans:`الكمية`},
                      {name:'Rate',trans:`معدل`},{name:'Amount',trans:`المبلغ`}]?.map((column) => (
                        <StyledTableCell key={column.name} style={{ fontWeight:600,textAlign:column?.align}}>
                          {column?.name}
                          {column?.trans && <Typography>{column.trans}</Typography>}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceDetails?.jobOrderServices?.map((row,index) => {
                      return (
                        <TableRow key={`Table-${index}`}>
                          <StyledTableCell>{index+1}</StyledTableCell>
                          <StyledTableCell style={{textAlign:'left'}}><Typography>{row.service_name}</Typography></StyledTableCell>
                          <StyledTableCell style={{textAlign:'right'}}><Typography>{row.qty}</Typography></StyledTableCell>
                          <StyledTableCell style={{textAlign:'right'}}><Typography>{row.price}</Typography></StyledTableCell>
                          <StyledTableCell style={{textAlign:'right'}}><Typography>{row?.amount}</Typography></StyledTableCell>
                        </TableRow>
                      );
                      })}
                      <TableRow>
                        <StyledTableCell colSpan={4}  style={{textAlign:'right'}}>
                          <Typography fontWeight={600}>Total</Typography>
                          <Typography fontWeight={600}>VAT (ضريبة)(+)</Typography>
                        </StyledTableCell>
                        <StyledTableCell  style={{textAlign:'right'}}>
                          <Typography>{invoiceDetails?.sub_total}</Typography>
                          <Typography>{invoiceDetails?.vat}</Typography>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow style={{backgroundColor:'#EEF1F6'}}>
                        <StyledTableCell colSpan={4} style={{textAlign:'right',fontWeight:'bold'}}>
                        <Typography fontWeight={600}>Net Amount (المبلغ نهائيا)</Typography></StyledTableCell>
                        <StyledTableCell style={{textAlign:'right'}}>
                        <Typography fontWeight={600}>SAR {invoiceDetails?.total}</Typography></StyledTableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>}
          </Box>
        </Box>
        }
      </Fragment>
    </Layout>
  )
}

export default SingleInvoice
