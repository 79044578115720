import { getCookie, removeCookie } from "app/hooks/useCookie";

export const ErrorHandler = (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            const token = getCookie('userinfo')?.token
            if (token) {
                removeCookie('userinfo')
                window.location.reload()
            }
        }
        return error.response.data.error;
    } else if (error.request) {
        return error.message;
    } else {
        return 'Something went wrong';
    }
};
