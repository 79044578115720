import { EditTwoTone } from '@mui/icons-material';
import { Box, FormControl, FormLabel, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, styled, tableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AircraftServices from 'app/apis/Aircraft/AircraftServices';
import ManageEngineersServices from 'app/apis/ManageEngineers/ManageEngineersServices';
import ManageFleetServices from 'app/apis/ManageFleet/ManageFleetService';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import {SecondaryButton } from 'app/components/Buttons/Button';
import Loader from 'app/components/Loader/Loader';
import StatusChip from 'app/components/StatusChip/StatusChip';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { Form, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',
    color: '#3F424B',fontSize:16,fontWeight:'bold',textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center'
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id: 'order_number',label: 'Job Order Number',align: 'left',clickAble:true},
  {id: 'start_date',label: 'Date',align: 'left'},
  {id: 'station',label: 'Station',align: 'left'},
  {id: 'order_status',label: 'Status',align: 'left'},
  {id:'payment_status',label:'Payment Status', align:'left'}
];

const CustomErrorBox = styled('div')(() => ({
    fontSize:'12px', color:colors.danger,margin:"8px 0px"
  }));

function CreateFleet() {
  const classes = useStyles();
  const {pathname}= useLocation();
  const {id,name} = useParams()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // loader
  const [isLoading, setIsLoading] = useState(false);
  const [allowEdit, setAllowEdit] = useState(id ? false : true);
  const [aircraftInformationData, setAircraftInformationData] = useState([]);
  const [aircraftTypes, setAircraftTypes] = useState([]);
  const [aircraftServed, setAircraftServed] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  // api data
  const [jobOrderListByAircraftId, setJobOrderListByAircraftId] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  // *Validation Schema
  const FleetSchema = Yup.object().shape({
    aircraft_type_id:Yup.number().required('Aircraft Type is Required'),
    aircraft_served_id:Yup.number().required('Aircraft Make is Required'),
    tail_number:Yup.string().required('Tail Number is Required'),
    msn_number:Yup.string().required('MSN Number is Required'),
  });

  // *For Getting Aircraft Types
  const getAircraftTypes = async () => {
    try {
      const { status, responseCode, data } = await AircraftServices.getAircraftTypes()
      if (status === true && responseCode === 200) {
        setAircraftTypes(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Aircraft
  const getAircraftServed = async (obj) => {
    try {
      const { status, responseCode, data } = await AircraftServices.getAircraftServed(obj)
      if (status === true && responseCode === 200) {
        setAircraftServed(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // JobOrderListByAircraftId
  const getJobOrderListByAircraftId = async (obj) => {
    try {
      const { status, responseCode, data,count } = await ManageFleetServices.getJobOrderListByAircraftId(obj)
      if (status === true && responseCode === 200) {
        setJobOrderListByAircraftId(data);
        setCount(count);
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Fleet By Id
  const getFleetById = async (id) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data } = await ManageFleetServices.getFleetById(id)
      if (status === true && responseCode === 200) {
        setAircraftInformationData(data);
        getAircraftServed({limit:1000,page:1,aircraft_type_id:data?.aircraft_type_id});
        getJobOrderListByAircraftId({id:data?.aircraft_served_id,limit:rowsPerPage,page:page});
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }
  // handleSubmitService
  const handleSubmitServiceEquipments = async (values) => {
    try {
      setLoading(true);
      if(id){
        const { status, responseCode, message } = await ManageFleetServices.patchFleetById({id:id,data:values  })
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1)
        }
      }
      else {
        const { status, responseCode, message } = await ManageFleetServices.postFleet(values)
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1)
        }
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(id){getFleetById(id)}
    getAircraftTypes();
    if(aircraftInformationData?.aircraft_served_id){
      getJobOrderListByAircraftId({id:aircraftInformationData?.aircraft_served_id,limit:rowsPerPage,page:page});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, id, aircraftInformationData?.aircraft_served_id, rowsPerPage, page]);

  return (
    <Layout>
    <Box>
    <Formik
        enableReinitialize
        initialValues={{
          aircraft_type_id:id ? aircraftInformationData?.aircraft_type_id : '',
          aircraft_served_id:id ? aircraftInformationData?.aircraft_served_id :'',
          tail_number:id ? aircraftInformationData?.tail_number : '',
          msn_number:id ? aircraftInformationData?.msn_number : '',
        }}
        validationSchema={FleetSchema}
        onSubmit={values => {handleSubmitServiceEquipments(values)}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <ArrowBackButton />
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>{id ? `${allowEdit ? 'Edit' : ''} ${name}` : 'Individual Aircraft Information'}</Typography>
            </Box>
            {id && 
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />}
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Manage Fleet',!id ? 'Create New Aircraft Information' : `${allowEdit ? 'Edit' : ''} Aircraft Information`]} />
          </Box>
          {isLoading ? <Loader /> :
          <Fragment>
            <Grid container xs={12} md={12} spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,my:2,
              boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Aircraft Type</FormLabel>
                <Select name="aircraft_type_id"  size="medium"
                  disabled={!allowEdit}  
                  value={values?.aircraft_type_id ? values?.aircraft_type_id : 0}
                  onChange={(e) => {
                    setFieldValue(`aircraft_type_id`, e?.target?.value);
                    setFieldValue(`aircraft_served_id`, '');
                    getAircraftServed({limit:1000,page:1,aircraft_type_id:+e?.target?.value})}}
                  sx={{color:values?.aircraft_type_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Aircraft Type
                  </MenuItem>
                  {aircraftTypes?.length > 0 ? aircraftTypes?.map((aircraftTypeItem,index) => (
                  <MenuItem key={index} value={aircraftTypeItem?.id}>{aircraftTypeItem?.type}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                {touched.aircraft_type_id && errors.aircraft_type_id && <CustomErrorBox>{errors.aircraft_type_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Aircraft Make</FormLabel>
                <Select name="aircraft_served_id"  size="medium"
                  disabled={!allowEdit || !values?.aircraft_type_id}  
                  value={values?.aircraft_served_id ? values?.aircraft_served_id : 0}
                  onChange={(e) => {setFieldValue(`aircraft_served_id`, e?.target?.value)}}
                  sx={{color:values?.aircraft_served_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Aircraft Make
                  </MenuItem>
                  {aircraftServed?.length > 0 ? aircraftServed?.map((aircraftServedItem,index) => (
                  <MenuItem key={index} value={aircraftServedItem?.id}>{aircraftServedItem?.name}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                {touched.aircraft_served_id && errors.aircraft_served_id && <CustomErrorBox>{errors.aircraft_served_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5, color:colors.primary}}>Aircraft Model</FormLabel>
                  <TextField disabled={!allowEdit} name="aircraft_model" label="Aircraft Model" size="medium"
                  value={values?.aircraft_model} onChange={(e) => {setFieldValue(`aircraft_model`, e?.target?.value)}} />
                  {touched.aircraft_model && errors.aircraft_model && <CustomErrorBox>{errors.aircraft_model}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5, color:colors.primary}}>Make</FormLabel>
                  <TextField disabled={!allowEdit} name="aircraft_make" label="Aircraft Make" size="medium"
                  value={values?.aircraft_make} onChange={(e) => {setFieldValue(`aircraft_make`, e?.target?.value)}} />
                  {touched.aircraft_make && errors.aircraft_make && <CustomErrorBox>{errors.aircraft_make}</CustomErrorBox>}
                </FormControl>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Tail Number</FormLabel>
                <TextField disabled={!allowEdit} name="tail_number" placeholder="Tail Number" size="medium"
                value={values?.tail_number} onChange={(e) => {setFieldValue(`tail_number`, e?.target?.value)}} />
                {touched.tail_number && errors.tail_number && <CustomErrorBox>{errors.tail_number}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>MSN Number</FormLabel>
                <TextField disabled={!allowEdit} name="msn_number" placeholder="MSN Number" size="medium"
                value={values?.msn_number} onChange={(e) => {setFieldValue(`msn_number`, e?.target?.value)}} />
                {touched.msn_number && errors.msn_number && <CustomErrorBox>{errors.msn_number}</CustomErrorBox>}
                </FormControl>
              </Grid>
            </Grid>
            {allowEdit && 
            <Box textAlign={'right'}>
              <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxStyle={{ml:2}}/>
            </Box>}
            {/* Previous Job Order */}
            <Fragment>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'30px',my:2}}>Aircraft Services Records</Typography>
              </Box>
              {jobOrderListByAircraftId?.length > 0 ? 
              <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
                <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
                <Table className={classes.table} stickyHeader aria-label="sticky table" >
                    <TableHead >
                      <TableRow >
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontWeight:600 }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jobOrderListByAircraftId?.map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <Fragment key={index}>
                                {(column.id === 'status' || column.id === 'payment_status') ? (
                                <StyledTableCell key={column.id} align={column.align}>
                                  <StatusChip value={value?.toLowerCase() === 'open' ? 'Upcoming' : value ?? 'Pending'} />
                                </StyledTableCell>
                                ) : (
                                <StyledTableCell key={column.id} align={column.align} sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                                onClick={() =>  {column.clickAble && navigate(`/view-job-order/${row?.job_order_number}`)}}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </StyledTableCell>)}
                                </Fragment>
                              );
                            })}
                          </TableRow>
                        );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25,50 ,100]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page -1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper> :
              <Box
                display={'flex'}
                p={6.25}
                alignItems={'center'}
                justifyContent={'center'}
              >
              <Typography variant="h5" fontWeight={400} >
                No Service History
              </Typography>
              </Box>}
            </Fragment>
          </Fragment>}
        </Form>
        )}
      </Formik>
    </Box>
    </Layout>
  )
}

export default CreateFleet