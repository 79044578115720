const JobOrderRoutes = {
    getJobOrderDetails: "/customer/jobOrderById/",
    getAllJobOrderDetails: "/customer/jobOrderList",
    postJobOrderDetails: "/customer/createJobOrder",
    updateJobOrderStatus:"/customer/updateJobOrderStatus/",
    getJobOrderForEditById:"/customer/jobOrderForEditById/",
    updateJobOrder:"/customer/updateJobOrder/",
    getVAT:"/customer/getVat",
    getTimeSlots:"/customer/jobOrderSlots",
  };
  
  export default JobOrderRoutes