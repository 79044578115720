import React, { useEffect, useState } from 'react'
import { Edit, RadioButtonUnchecked } from '@mui/icons-material'
import { Box,List,ListItem,ListItemText,Typography, styled } from '@mui/material'
import { useParams,useNavigate, } from 'react-router'
// Component Import
import JobOrderServices from 'app/apis/JobOrder/JobOrderService'
import { SecondaryButton } from 'app/components/Buttons/Button'
import { ErrorToaster } from 'app/components/Toaster/Toaster'
import Layout from 'app/layout/Layout'
import colors from 'app/styles/colors'
import TaskList from './TaskList'
import Images from 'app/assets/Images'
import ServicesTable from './ServicesTable'
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import StatusChip from 'app/components/StatusChip/StatusChip';
import Loader from 'app/components/Loader/Loader'

const CustomImageTag = styled('img')(() => ({
  objectFit: 'contain', width: '115px', height: '115px',border:`2px solid ${colors?.primaryLight}`,borderRadius:5,padding:'15px'
}));


const policyData = [
  {
    title: 'Scope of Services:',
    points: [
      'The scope of this job order is specific to the listed services. Any additional services requested while performing the job order will be considered added services and charged accordingly.'
    ]
  },
  {
    title: 'Access to Aircraft:',
    points: [
      'The customer must provide necessary access to the aircraft at the scheduled time for service.',
      'In the event of any delays or changes to the service schedule, the customer agrees to notify the service provider promptly.',
      'If the customer delays providing access to the aircraft, the service provider shall not be liable for any delays in delivery time.',
      'The customer is responsible for providing the aircraft at the service location decided. The service provider will not perform any ground handling for the aircraft.',
      'The customer is responsible for obtaining necessary permits for work to be conducted on the aircraft within the parking area within the private aviation aprons, including clearance from security and relevant airport authorities. By clearly delineating these responsibilities, potential delays can be mitigated, and compliance with regulatory requirements ensured.'
    ]
  },
  {
    title: 'Liability:',
    points: [
      'Prior to initiating the services, a representative from the customer must be present during the walk-around or pre-inspection to identify any concerns. A representative must also be present in the post-service inspection to sign off on the job order and receive the aircraft.',
      'In the case of a delay or no-show of the customer representative to the aircraft, the service shall not be performed, and the customer will be billed for the services.',
      'While utmost care will be taken during the cleaning and detailing process, the service provider shall not be held liable for any damages to the aircraft or its components unless caused by negligence of service and proven to be caused by its technical team.',
      'The customer acknowledges that they are responsible for securing all personal belongings and valuables within the aircraft prior to the commencement of services. In the event of any loss of items, the service provider shall not be liable.'
    ]
  },
  {
    title: 'Weather Conditions:',
    points: [
      'Aircraft washing and detailing services may be affected by inclement weather conditions.',
      'The service provider reserves the right to reschedule the service in the event of adverse weather conditions that may compromise the quality of the work and safety.'
    ]
  },
  {
    title: 'Rescheduling and Cancellation:',
    points: [
      'Rescheduling of service must take place 24 hours prior to the service date. In exceptional circumstances, the service provider may accept orders with less notice, subject to availability.',
      'Cancellations made less than 24 hours before the scheduled service may be subject to a cancellation fee.',
      'The service provider reserves the right to charge a cancellation fee to compensate for scheduling and resource allocation.'
    ]
  },
  {
    title: 'Acceptance of Job Order:',
    points: [
      'By accepting this job order, the customer agrees to the terms and conditions outlined herein.',
      'Upon acceptance, the customer acknowledges their understanding of the services to be provided and agrees to the associated costs.'
    ]
  }
];

function ViewJobOrder() {
  const {id} = useParams();
  const navigate = useNavigate();
  // loader
  const [isLoading, setIsLoading] = useState(false);
  const [jobOrderDetail, setJobOrderDetail] = useState('');
  const [openTaskList, setOpenTaskList] = useState(false);
  // main info
  const mainInformation =[
    {label:'Service Provider',value:'Green & Clean'},
    {label:'Address',value:'Sari Gate Business Center 208'},
    {label:'Contact',value:'021 667 9988'},
    {label:'Job Supervisor:',value:jobOrderDetail?.supervisor_name},
  ]
  // secondary info
  const secondaryInformation =[
    {label:'Customer Name',value:jobOrderDetail?.customer_name},
    {label:'Aircraft Engineer',value:jobOrderDetail?.aircraft_engineer_name},
    {label:'Aircraft Type',value:jobOrderDetail?.customer?.aircraft},
    {label:'Aircraft MSN',value:jobOrderDetail?.supervisor_name},
    {label:'Tail Number',value:jobOrderDetail?.customer?.tail_number},
  ]
  // *For Getting Service
  const getJobOrderDetails = async (id) => {
      try {
        setIsLoading(true);
        const { status, responseCode, data } = await JobOrderServices.getJobOrderDetails(id)
        if (status === true && responseCode === 200) {
          setJobOrderDetail(data);
          setIsLoading(false);
        }
      } catch (error) {
        ErrorToaster(error);
        setIsLoading(false);
      }
    }
    
  useEffect(() => {
    getJobOrderDetails(id)
  }, [id]);

  return (
    <Layout>
      <>
      <Box sx={{display:'flex', alignItems:'center',justifyContent:'space-between'}}>
        <Box sx={{display:'flex', alignItems:'center',}}>
          <ArrowBackButton />
          <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>Job Order Details</Typography>
          </Box>
          {jobOrderDetail?.order_status?.toLowerCase() !== 'closed' && <SecondaryButton title={"Edit Job Order"}  onClick={() => navigate(`/edit-job-order/${jobOrderDetail?.order_number}/${jobOrderDetail?.id}`)} endIcon={<Edit />} />}
      </Box>
      <Box sx={{ml:8}}>
        <BreadCrumbs currentPage={['Job Orders','View Job Order']} />
      </Box>
      {isLoading ? <Loader /> : 
      <Box sx={{borderRadius:'5px', boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',p:3, backgroundColor:colors.white}}>
        <Box sx={{display:'flex', flex:1,alignItems:'center', justifyContent:'space-between', }}>
        <img src={Images.logo} width={'240px'} alt="Logo"/>
          <Box>
          <Box sx={{display:'flex', alignItems:'center', my:1}}>
          <Typography fontSize={18} color={colors.secondaryDark} fontWeight={600} mr={2} >Order Status: </Typography>
          <StatusChip value={jobOrderDetail?.order_status?.toLowerCase() === 'open' ? 'Upcoming' : jobOrderDetail?.order_status} />
          </Box>
          <Typography color={colors.secondaryDark} fontWeight={600} fontSize={18} >Job Order #: <span style={{fontWeight:'normal'}}>{jobOrderDetail?.order_number}</span> </Typography>
          <Typography color={colors.secondaryDark} sx={{textTransform:"capitalize"}} fontWeight={600} fontSize={18} >Date :<span style={{fontWeight:'normal'}}>{jobOrderDetail?.start_date} - {jobOrderDetail?.start_time}</span></Typography>
          {/* <Typography color={colors.secondaryDark} fontWeight={600} fontSize={18} >Location: <span style={{fontWeight:'normal'}}>{jobOrderDetail?.city}</span> </Typography> */}
          <Typography color={colors.secondaryDark} fontWeight={600} fontSize={18} >Station: <span style={{fontWeight:'normal'}}>{jobOrderDetail?.stations_name}</span> </Typography>
          </Box>
        </Box>
        <Box sx={{display:'flex', justifyContent:'space-between',flexWrap:'wrap' ,mt:6}}>
          <Box>
            {mainInformation?.map((info,index) => (
              <Box key={`main-info-${index}`} sx={{display:'flex', alignItems:'center', my:0.25}}>
                <Typography fontSize={18} color={colors.secondaryDark} fontWeight={600} width={"200px"} >{info?.label}: </Typography>
                <Typography fontSize={18} color={colors.secondaryDark} sx={{textTransform:"capitalize", ml:2}} >{info?.value}</Typography>
              </Box>
            ))}
          </Box>
          <Box my={{xs: 2, md:0}}>
          {secondaryInformation?.map((secondInfo,index) => (
            <Box key={`secondary-info-${index}`} sx={{display:'flex', alignItems:'center', my:0.25}}>
              <Typography fontSize={18} color={colors.secondaryDark} fontWeight={600} width={"250px"} >{secondInfo?.label}: </Typography>
              <Typography fontSize={18} color={colors.secondaryDark} sx={{textTransform:"capitalize", ml:2}} >{secondInfo?.value}</Typography>
            </Box>
          ))}
          </Box>
        </Box>
        {/* Service Table */}
        <ServicesTable data={jobOrderDetail?.services ? jobOrderDetail?.services : [] } 
        summary={{sub_total:jobOrderDetail?.sub_total,vat:jobOrderDetail?.vat,total:jobOrderDetail?.total}} />
        <Typography sx={{ p:2, backgroundColor:'#eee', fontWeight:600, fontSize:18, color:colors.secondaryDark}}>Signatories:</Typography>
        <Box sx={{display:'flex', alignItems:'center', my:2,}}>
          <Box>
            <Box sx={{display:'flex', alignItems:'center',mb:1.5}}>
              <Typography sx={{color:colors.secondaryDark, fontWeight:600, fontSize:18}}>Operation Supervisor:</Typography>
              <Typography sx={{color:colors.secondaryDark, fontSize:18, ml:2}}>{jobOrderDetail?.supervisor_name}</Typography>
            </Box>
            {jobOrderDetail?.signed_by_supervisor && 
              <CustomImageTag src={jobOrderDetail?.signed_by_supervisor} alt="Supervisor Image" />}
          </Box>
          <Box ml={8}>
          <Box sx={{display:'flex', alignItems:'center',mb:1.5}}>
            <Typography sx={{color:colors.secondaryDark, fontWeight:600, fontSize:18}}>Aircraft Engineer:</Typography>
            <Typography sx={{color:colors.secondaryDark, fontSize:18, ml:2}}>{jobOrderDetail?.aircraft_engineer_name}</Typography>
          </Box>
          {jobOrderDetail?.signed_by_aircraft_engineer && 
            <CustomImageTag src={jobOrderDetail?.signed_by_aircraft_engineer} alt="Aircraft Image" />}
          </Box>
        </Box>
        <SecondaryButton title={"Check List"} onClick={() => setOpenTaskList(true)}  />
        {/* Terms & Policy */}
        <Box my={2}>
          <Typography sx={{ p:2, backgroundColor:'#eee', fontWeight:600, fontSize:18, color:colors.secondaryDark}}>Terms & Policy:</Typography>
          <ol style={{fontWeight:600}}>
            {policyData.map((policy, index) => (
              <li key={index}>
                <Typography variant="h6" fontWeight={600}>{policy.title}</Typography>
                <ul>
                  {policy.points.map((point, pointIndex) => (
                    <li key={pointIndex}>
                      <Typography>{point}</Typography>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ol>
        </Box>
      </Box>}
      {openTaskList && 
      <TaskList data={jobOrderDetail?.checkList}
      openTaskList={openTaskList} setOpenTaskList={setOpenTaskList} orderNumber={jobOrderDetail?.order_number} />}
      </>
    </Layout>
  )
}

export default ViewJobOrder