import { useParams } from 'react-router-dom';
import { FormControl, FormLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// Component Import
import colors from 'app/styles/colors';
import { CustomErrorBox } from './CreateJobOrder';
import { Add, CalendarMonthOutlined } from '@mui/icons-material';
import moment from 'moment';
import useAuth from 'app/hooks/useAuth';

function BasicInformation({allowEdit,values,errors,touched,setFieldValue,aircraft,
  aircraftDetails,setAircraftDetails,stations,setServiceDataOptions,aircraftEngineers}) {
  
  const { name } = useAuth();
  const {id=""} = useParams();
  

  // handle aircraft details
  const handleAircraftDetails = (value) =>{
    if(value){
      const details = aircraft?.find((e) => e?.aircraft_served_id === value)
      setAircraftDetails(() => {return {tail_number:details?.tail_number,msn_number:details?.msn_number}})
    }
  }

  // *For getting services by changing aircraft 
  const getServicesByAircraftAndTime = (values,setFieldValue) => {
    if(values?.time && values?.aircraft_id){
      for (let servicesIndex = 0; servicesIndex < values?.services?.length; servicesIndex++) {
        setFieldValue(`services[${servicesIndex}]`,{service_category_id: '',service_category_name:'',services:[]});
        setServiceDataOptions([])
      }
    }
  }
  
  return (
    <Grid container spacing={2} >
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
        <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Customer</FormLabel>
        <TextField disabled name="customer" value={name} placeholder="Customer Name" size="lg" />
        </FormControl>
      </Grid>
      { !id && <Grid item xs={12} md={6}>
        <FormControl fullWidth>
        <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Station</FormLabel>
        <Select name="station_id"  size="medium"
          disabled={!allowEdit}  
          value={values?.station_id ? values?.station_id : 0}
          onChange={(e) => {setFieldValue(`station_id`, e?.target?.value)}}
          sx={{color:values?.station_id === '' ? colors.labelColor : ''}} >
          <MenuItem value={0} disabled sx={{display:'none'}}>
            Select Station
          </MenuItem>
          {stations?.length > 0 ? stations?.map((stationItem,index) => (
          <MenuItem key={index} value={stationItem?.id}>{stationItem?.name}</MenuItem>
        )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
        </Select>
        {touched.station_id && errors.station_id && <CustomErrorBox>{errors.station_id}</CustomErrorBox>}
        </FormControl>
      </Grid>}
      <Grid item xs={12} md={6}>
        <FormControl fullWidth >
        <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Aircraft</FormLabel>
        <Select name="aircraft_id"  size="medium"
          disabled={!allowEdit}  
          value={values?.aircraft_id ? values?.aircraft_id : 0}
          onChange={(e) => {setFieldValue(`aircraft_id`, e?.target?.value);handleAircraftDetails(e?.target?.value);
          getServicesByAircraftAndTime(values,setFieldValue)}}
          sx={{color:values?.aircraft_id === '' ? colors.labelColor : ''}} >
          <MenuItem value={0} disabled sx={{display:'none'}}>
            Select Aircraft
          </MenuItem>
          {aircraft?.length > 0 ? aircraft?.map((aircraftItem,index) => (
          <MenuItem key={index} value={aircraftItem?.aircraft_served_id}>{aircraftItem?.aircraft_served_name}</MenuItem>
        )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
        </Select>
        {touched.aircraft_id && errors.aircraft_id && <CustomErrorBox>{errors.aircraft_id}</CustomErrorBox>}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
        <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Job Order Start Date</FormLabel>
          <DatePicker customInput={<TextField fullWidth 
          InputProps={{endAdornment: (<CalendarMonthOutlined />)}} />} placeholderText="Job Order Start Date" 
          disabled={!allowEdit} name="date" dateFormat="yyyy-MM-dd"
          selected={values?.date ? new Date(values?.date):values?.date} minDate={new Date()} 
          onChange={(date) => {setFieldValue(`date`,moment(date)?.format('YYYY-MM-DD'));}} />
          {touched.date && errors.date && <CustomErrorBox>{errors.date}</CustomErrorBox>}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
        <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Aircraft Tail Number</FormLabel>
        <TextField disabled={true} name="aircraft_tail_number" placeholder="Asset Tail Number" size="medium" value={aircraftDetails?.tail_number}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
        <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Aircraft MSN</FormLabel>
        <TextField disabled={true} name="aircraft_msn" placeholder="Aircraft MSN" size="medium" value={aircraftDetails?.msn_number}
        />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
        <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Aircraft Engineer</FormLabel>
        <Select name="aircraft_engineer_id"  size="medium"
          disabled={!allowEdit}  
          value={values?.aircraft_engineer_id ? values?.aircraft_engineer_id : 0}
          onChange={(e) => {setFieldValue(`aircraft_engineer_id`, e?.target?.value)}}
          sx={{color:values?.aircraft_engineer_id === '' ? colors.labelColor : ''}} >
          <MenuItem value={0} disabled sx={{display:'none'}}>
            Select Aircraft Engineer
          </MenuItem>
          {aircraftEngineers?.length > 0 ? aircraftEngineers?.map((aircraftEngineerItem,index) => (
          <MenuItem key={index} value={aircraftEngineerItem?.id}>{aircraftEngineerItem?.aircraft_engineer_name}</MenuItem>
        )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
        </Select>
        {touched.aircraft_engineer_id && errors.aircraft_engineer_id && <CustomErrorBox>{errors.aircraft_engineer_id}</CustomErrorBox>}
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
        <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Apron</FormLabel>
        <TextField disabled={!allowEdit} name="apron" placeholder="Apron" size="medium" 
        value={values?.apron} onChange={(e) => {setFieldValue(`apron`, e?.target?.value)}} />
        {touched.apron && errors.apron && <CustomErrorBox>{errors.apron}</CustomErrorBox>}
        </FormControl>
      </Grid>
  </Grid>
  )
}

export default BasicInformation