// React Import
import React, { useState,useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
// Material UI Import
import { Close } from '@mui/icons-material'
import { Box, FormControl, FormLabel, Modal, TextField, Typography, styled } from '@mui/material';
// Service Import
import ManageEngineersServices from 'app/apis/ManageEngineers/ManageEngineersServices';
// Formik Import
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// Component Import
import colors from 'app/styles/colors';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';

const CustomErrorBox = styled('div')(() => ({
  fontSize:'14px', color:colors.danger,margin:"10px 4px"
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 555,
  backgroundColor: 'background.paper',
  boxShadow: 24,borderRadius:2,
  p: 2,
};

const engineerSchema = Yup.object().shape({
  name: Yup.string().required("Category Name is required"),
  email: Yup.string().required("Email is required"),
});


function CreateEngineer({setRefresh}) {
  const navigate = useNavigate();
  const {id} = useParams();
	const [loading, setLoading] = useState(false);
  
  const handleSubmitEngineer = async (values) => {
    try {
      setLoading(true)
      const { status, responseCode, message } = await ManageEngineersServices.postEngineer(values)
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        setRefresh(true);
        navigate(-1)
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false)
  }


  return (
    <Modal
    open={true}>
    <Box sx={style}>
		  <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
        <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'30px'}}>Add New Engineer</Typography>
        <Close sx={{cursor:'pointer'}} onClick={() => navigate(-1)}/>
      </Box>
      <Formik
        enableReinitialize
        initialValues={{name: '', email: ''}}
        validationSchema={engineerSchema}
      	onSubmit={values => {handleSubmitEngineer(values)}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <FormControl fullWidth>
 						<FormLabel sx={{my:1.5, color:colors.formLabel, fontWeight:'bold'}}>Name</FormLabel>
 						<TextField name="name" placeholder='Name' size="medium" value={values?.name} onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} />
 						{touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
 					</FormControl>
          <FormControl fullWidth>
 						<FormLabel sx={{my:1.5, color:colors.formLabel, fontWeight:'bold'}}>Email</FormLabel>
 						<TextField name="email" placeholder='Email' size="medium" type='email' value={values?.email} disabled={id ? true : false}
            onChange={(e) => {setFieldValue(`email`, e?.target?.value)}} />
 						{touched.email && errors.email && <CustomErrorBox>{errors.email}</CustomErrorBox>}
 					</FormControl>
					<Box sx={{textAlign:'right',my:2}}>
            <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
            <CreateButton title={ "Create"} type="submit" loading={loading} sxStyle={{ml:2}}/>
					</Box>
				</Form>
				)}
			</Formik>
    </Box>
    </Modal>
  )
}

export default CreateEngineer