import React from 'react';
import { FormControl, OutlinedInput, TextField, Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'
import { makeStyles } from '@mui/styles';


import { Error } from './Error';

const useStyles = makeStyles({
  borderClass: {
    "&.react-tel-input .form-control": {
      borderColor: "#D32F2F",
      border: "1px solid #D32F2F",
    }
  }
})

export function InputField({ error, register, type, readOnly, defaultValue, disable }) {
  return (
    <FormControl size={"medium"} fullWidth  >
      <TextField
        disabled={disable}
        inputProps={
          { readOnly: readOnly ? true : false }
        }
        sx={{mb: 0,backgroundColor:'white',borderRadius:'12px'}}
        defaultValue={defaultValue}
        type={type}
        error={error && true}
        {...register}
      />
      {error && (
        <Typography sx={{color:'white',textAlign:'left',fontSize:13,mb:1}}>{error}</Typography>
      )}
    </FormControl>
  )
}

export function OutlineInputField({ label,error, register, endAdornment, updatedValue, type, size,onClick, disable }) {
  return (
    <FormControl size={"medium"} fullWidth>
      {/* <InputLabel error={error && true}>{label}</InputLabel> */}
      <OutlinedInput
        size='medium'
        disabled={disable}
        onClick={onClick}
        endAdornment={endAdornment}
        type={type}
        sx={{m: 0,backgroundColor:'white',borderRadius:'12px'}}
        value={updatedValue}
        placeholder={label}
        error={error && true}
        {...register}
      />
      {error && (
        <Typography sx={{color:'white',textAlign:'left',fontSize:13,mb:1}}>{error}</Typography>
      )}
    </FormControl>
  )
}

export function PhoneInputField({ value, onChange, label, error, register, disable, endAdornment, updatedValue, type, size, onClick }) {
  const classes = useStyles()
  return (
    <FormControl size="small" fullWidth variant="outlined" sx={{ mb: error ? 0 : 2 }}>
      <PhoneInput
        disabled={disable}
        country='pk'
        inputStyle={{
          width: "100%",
        }}
        containerClass={error ? classes.borderClass : ""}
        value={value}
        placeholder='+92 333-3333333'
        error={error && (true)}
        {...register}
        onChange={onChange}
      />
      {error && (
        <Error message={error} />
      )}
    </FormControl>
  )
}



