import React, { Fragment, useState } from "react";
import { Grid, Box,  Menu, Avatar, Typography, Divider} from "@mui/material";
import { SettingsOutlined,} from "@mui/icons-material";

import useAuth from "app/hooks/useAuth";
import colors from "app/styles/colors";
import Images from "app/assets/Images";

//  *Imported Component
import { PrimaryButton } from "app/components/Buttons/Button";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const { logout, name, email,logo } = useAuth();

  // *Anchor Box
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    }
    else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment >
      <Grid container py={1.5} px={3} 
        // justifyContent={"space-between"}
          sx={{boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;',borderRadius:'10px',backgroundColor: colors.white,boxSizing:'border-box' }}
      >
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={9}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              float: "right",
            }}
          >
            <Box
              onClick={(e) => { handleClick(e) }}
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
                borderRadius: "22px",
                backgroundColor: open ? colors.secondary : colors.lightGray,
                "&:hover": {
                  backgroundColor: colors.secondary,
                  cursor: "pointer",
                  ".MuiSvgIcon-root": { color: colors.lightGray },
                },
              }}
            >
              {logo !== 'null' && logo !== null && logo !== undefined ? (<Avatar src={logo} alt="Customer Profile" sx={{width:'35px',height:'35px'}}  />) : (
                <Avatar 
                sx={{ borderRadius: '50%',backgroundColor:colors.springGreen,
                textTransform:'uppercase',width:'34px',height:'34px',fontSize:15 }}>{name[0]+name[1]}</Avatar>
              )}
              <SettingsOutlined
                sx={{ color: open ? colors.lightGray : colors.secondary, fontSize: "26px", ml: 2 }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}

        PaperProps={{
          elevation: 0,
          sx: {
            width: '350px',height:'280px',
            px: 2,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 60,
              height: 60,
              ml: 1,
              mr: 1,

            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Typography sx={{
          fontSize: '16px', fontWeight: 500, color: colors.black,
          p: 1.5
        }}>User Profile</Typography>
        <Box sx={{ display: 'flex', alignItems: "center", cursor: "pointer" }}>
          {logo !== 'null' && logo !== null && logo !== undefined ? (<Avatar src={logo} sx={{ borderRadius: '50%' }} />) : (
            <Avatar sx={{ borderRadius: '50%',backgroundColor:colors.springGreen,textTransform:'uppercase' }} >{name[0]+name[1]}</Avatar>
          )}
          <Box>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: "bold"
              }}>{name}</Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                color: colors.textSecondary
              }}>{email}</Typography>
          </Box>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box onClick={() => navigate('/my-profile')}  sx={{ display: 'flex', alignItems:'center',p: 1, borderRadius: "8px",cursor:'pointer','&:hover':{backgroundColor:'rgba(0, 128, 53,0.15)'} }}>
          <Box sx={{p:0.5, boxSizing:'border-box', bgcolor: colors.primaryLight, mr:1,display:'flex', alignItems:'center', borderRadius:'50%'}}>
          <img src={Images.logo} alt="Company Profile" style={{width:'55px', height:'55px', borderRadius:'50%'}} /></Box>
          <Box >
            <Typography
              sx={{
                fontSize: '1rem',

              }}>My Profile</Typography>
            <Typography variant="h6"
              sx={{
                fontSize: '0.875rem',
                color: colors.textSecondary
              }}>Account Settings</Typography>
          </Box>
        </Box>
        <Box sx={{ my: 1.5 }}>
          <PrimaryButton
            data-testId="logout-btn"
            title="logout"
            onClick={logout}
            fullWidth={true}
            sxStyle={{backgroundColor:colors.springGreen,"&:hover":{backgroundColor:colors.springGreen}}}
          />
        </Box>
      </Menu>
    </Fragment>
  );
}

export default Header;
