import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
// Date Time LIb Import
import dayjs from 'dayjs';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useAuth from 'app/hooks/useAuth';
// Formik Import
import { FieldArray } from 'formik';
// Service Import
import JobOrderServices from 'app/apis/JobOrder/JobOrderService';
// Component Import
import { AutocompleteInput } from 'app/components/Input/Autocomplete';
import colors from 'app/styles/colors';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import ServiceCategoryServices from 'app/apis/ServiceCategory/ServiceCategoryServices';
import ServicesTable from './ServicesTable';
import { numberSARFormat } from 'app/utils/helper';

function JobOrderSummary({values,setFieldValue,stations,aircraft,aircraftDetails,aircraftEngineers,serviceCategories,serviceDataOptions}) {
  
  const { name } = useAuth();
  const {id=''} = useParams();
  // Pricing Details
  const [subTotal, setSubTotal] = useState(0);
  const [VAT, setVAT] = useState(0);

  // handle prices
  const handlePrices = (value) => {
    let newServices = [...values?.services]
    for (let serviceIndex = 0; serviceIndex < newServices?.length; serviceIndex++) {
      for (let servicePrice = 0; servicePrice < newServices[serviceIndex]?.services.length; servicePrice++) {
        if(value?.service_id === newServices[serviceIndex]?.services[servicePrice]?.service_id){
          newServices[serviceIndex]?.services?.splice(servicePrice,1,value)
        }
      }      
    }
    setFieldValue('services',newServices);
  }

  // handle Total Summary Details
  const handleTotals = (value) => {
    let price = 0
    for (let serviceIndex = 0; serviceIndex < value?.length; serviceIndex++) {
        price = +price + value[serviceIndex]?.price
        handlePrices(value[serviceIndex])
    }
    setSubTotal(price);
  } 

  // For Getting  VAT
  const getVAT = async () => {
    try {
      const { status, responseCode, data } = await JobOrderServices.getVAT()
      if (status === true && responseCode === 200) {
        setVAT(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // For Getting Services Prices
  const getServicesPriceByTime = async (obj) => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getServicesPriceByTime(obj)
      if (status === true && responseCode === 200) {
        handleTotals(data);
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // For converting data of selected services and category for service summary table
  const handleServiceSummary = (selectedServices=[]) => {
    const convertedArrayObject = [];
    
    for (const serviceCategory of selectedServices) {
      const { service_category_name, services } = serviceCategory;
      
      let isFirstIteration = true; // Flag to track the first iteration
      
      if (services && services.length > 0) {
        for (const service of services) {
          const { service_name, price } = service;
          const obj = {
            serviceCategory: service_category_name,
            serviceName: service_name,
            servicePrice: price
          };
          
          // Add rowSpan key only for the first iteration
          if (isFirstIteration) {
            obj.rowSpan = services.length;
            isFirstIteration = false; // Update the flag after the first iteration
          }
          
          convertedArrayObject.push(obj);
        }
      }
    }
    
    return convertedArrayObject;
  }
  
  useEffect(() => {
    getVAT();
    const price_variation_ids = []
    for (let serviceIndex = 0; serviceIndex < values?.services?.length; serviceIndex++) {
      for (let serviceVariationIndex = 0; serviceVariationIndex < values?.services[serviceIndex]?.services?.length; serviceVariationIndex++) {
        if(values?.services[serviceIndex]?.services[serviceVariationIndex]?.service_price_variation_id){
          price_variation_ids?.push(values?.services[serviceIndex]?.services[serviceVariationIndex]?.service_price_variation_id)
        }
      }
    }
    if(price_variation_ids?.length > 0){
      getServicesPriceByTime({time:values.time.start_time,service_price_variations_ids:price_variation_ids});
    }
    if(id){
      let newPrice = 0
      for (let serviceIndex = 0; serviceIndex < values?.services?.length; serviceIndex++) {
        for (let servicePriceIndex = 0; servicePriceIndex < values?.services[serviceIndex]?.services.length; servicePriceIndex++) {
          if(values?.services[serviceIndex]?.services[servicePriceIndex]?.price){
            newPrice += values?.services[serviceIndex]?.services[servicePriceIndex]?.price
          }
        }  
      }
      setSubTotal(newPrice)
    }
  }, [id]);
  
  return (
    <Grid container spacing={2}>
     <Grid item xs={12} md={6}>
        <FormControl fullWidth>
        <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Customer</FormLabel>
        <TextField disabled name="customer" value={name} placeholder="Customer Name" size="lg" />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Station</FormLabel>
          <Select name="station_id"  size="medium"
            disabled={true}  
            value={values?.station_id} >
            {stations?.length > 0 ? stations?.map((stationItem,index) => (
            <MenuItem key={index} value={stationItem?.id}>{stationItem?.name}</MenuItem>
          )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth >
          <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Aircraft</FormLabel>
          <Select name="aircraft_id"  size="medium"
            disabled={true}  
            value={values?.aircraft_id} >
            {aircraft?.length > 0 ? aircraft?.map((aircraftItem,index) => (
            <MenuItem key={index} value={aircraftItem?.aircraft_served_id}>{aircraftItem?.aircraft_served_name}</MenuItem>
          )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl fullWidth>
          <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Date</FormLabel>
          <TextField type="date" disabled={true} name="date" placeholder="Job Order Start Date" size="lg" 
            value={values?.date}/>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl fullWidth>
          <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>From</FormLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}  >
            <MobileTimePicker disabled={true} name="time" placeholder="Job Order Start Time"
            value={values?.date && values?.time?.start_time ? dayjs(`${values?.date}T${values?.time?.start_time}`) : null} />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2}>
        <FormControl fullWidth>
          <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>To</FormLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}  >
            <MobileTimePicker disabled={true} name="time" placeholder="Job Order Start Time"
            value={values?.date && values?.time?.end_time ? dayjs(`${values?.date}T${values.time?.end_time}`) : null} />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Aircraft Tail Number</FormLabel>
          <TextField disabled={true} name="aircraft_tail_number" placeholder="Asset Tail Number" size="medium" value={aircraftDetails?.tail_number}
            />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Aircraft MSN</FormLabel>
          <TextField disabled={true} name="aircraft_msn" placeholder="Aircraft MSN" size="medium" value={aircraftDetails?.msn_number}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Aircraft Engineer</FormLabel>
          <Select name="aircraft_engineer_id"  size="medium"
            disabled={true}  
            value={values?.aircraft_engineer_id ? values?.aircraft_engineer_id : 0}
            onChange={(e) => {setFieldValue(`aircraft_engineer_id`, e?.target?.value)}}
            sx={{color:values?.aircraft_engineer_id === '' ? colors.labelColor : ''}} >
            <MenuItem value={0} disabled sx={{display:'none'}}>
              Select Aircraft Engineer
            </MenuItem>
            {aircraftEngineers?.length > 0 ? aircraftEngineers?.map((aircraftEngineerItem,index) => (
            <MenuItem key={index} value={aircraftEngineerItem?.id}>{aircraftEngineerItem?.aircraft_engineer_name}</MenuItem>
          )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
        </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Apron</FormLabel>
          <TextField disabled={true} name="apron" placeholder="Apron" size="medium" 
          value={values?.apron} />
        </FormControl>
      </Grid>
      {/* Services Section */}
      <Grid item xs={12} md={12} container>
        <FieldArray
          name="services"
          render={arrayHelpers => (
          <Grid item container xs={12} md={12} >
            <Grid item xs={12} md={12}>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'30px'}}>Services:</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              {values?.services?.map((_, index) => (
                <Grid item container spacing={2}  key={index} alignItems={'flex-start'}>
                  <Grid item xs={12} md={values?.services?.length === 1 ? 6 : 5.70}>
                    <FormControl fullWidth size="small">
                      <FormLabel sx={{my:1,  color:colors.formLabel, fontWeight:'bold'}}>Service Category</FormLabel>
                      <Select
                        size='medium' disabled={true}
                        name={`services[${index}].service_category_id`}
                        value={values.services[index]?.service_category_id}
                        sx={{color:values.services[index]?.service_category_id === '' ? colors.labelColor : ''}}
                      >
                      <MenuItem value={0} disabled sx={{display:'none'}}>
                        Select Service Category
                      </MenuItem>
                      {serviceCategories?.map((item,index) => (
                        <MenuItem key={index} value={item?.id} 
                        sx={{display: values?.services?.some((service) => service?.service_category_id === item?.id) ? 'none' : 'block'}}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={values?.services?.length === 1 ? 6 : 5.70}>
                  <FormControl fullWidth size="small">
                    <FormLabel sx={{ my:1, color:colors.formLabel, fontWeight:'bold'}}>Add Service</FormLabel>
                    <AutocompleteInput name={`services[${index}].services`} limitTag={1} identifier='service_name'
                      disabled={true} 
                      data={serviceDataOptions[index]?.length > 0 ? serviceDataOptions[index] : []}  
                      value={values?.services[index]?.services} />
                  </FormControl>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          )}
        />
      </Grid>
      {/* Service Table */}
      <Grid item xs={12} md={12}>
        <ServicesTable data={handleServiceSummary(values?.services)} 
          summary={{sub_total:subTotal,vat:(+subTotal * (VAT/100)),total:+(subTotal) + +(+subTotal*(VAT/100))}} />
      </Grid>
      {/* Summary Pricing */}
      <Grid item xs={12} md={12} my={3}>
        <Box sx={{display:'flex', alignItems:'center', float:'right', backgroundColor:'#EEF1F6',width:'max-content',p:1.25,borderRadius:'8px'}}>
          {/* Sub Total */}
          <Box sx={{display:'flex', alignItems:'baseline',mx:2}}>
            <Typography sx={{fontSize:16, fontWeight:'bold', color:'#717274',mr:2}}>Sub Total:</Typography>{' '}
            <Typography sx={{fontSize:24, fontWeight:'bold', color:'#717274'}}>${numberSARFormat(subTotal)}</Typography>
          </Box>
          {/* VAT */}
          <Box sx={{display:'flex', alignItems:'baseline',mx:2}}>
            <Typography sx={{fontSize:16, fontWeight:'bold', color:'#717274',mr:2}}>VAT:</Typography>{' '}
            <Typography sx={{fontSize:24, fontWeight:'bold', color:'#717274'}}>${numberSARFormat(+subTotal * (VAT/100))}</Typography>
          </Box>
          {/* Total */}
          <Box sx={{display:'flex', alignItems:'baseline',mx:2}}>
            <Typography sx={{fontSize:16, fontWeight:'bold', color:'#717274',mr:2}}>Total:</Typography>{' '}
            <Typography sx={{fontSize:24, fontWeight:'bold', color:'#717274'}}>${numberSARFormat(+(subTotal) + +(+subTotal*(VAT/100)))}</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default JobOrderSummary