import { get} from 'app/apis';
import AircraftRoutes from './Aircraft.Routes';

const AircraftServices = {
  getAircraftServed: async (obj) => {
    const data = await get(AircraftRoutes.getAircraftServed+`?limit=${obj.limit}&page=${obj.page}&aircraft_type_id=${obj.aircraft_type_id}`);
    return data;
  },
  getAircraftTypes: async () => {
    const data = await get(AircraftRoutes.getAircraftTypes);
    return data;
  },
  getStations: async () => {
    const data = await get(AircraftRoutes.getStations);
    return data;
  },
}

export default AircraftServices