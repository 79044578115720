import { get } from 'app/apis';
import ServiceCategoryRoutes from './ServiceCategory.Routes';

const ServiceCategoryServices = {
  getServiceCategories: async () => {
    const data = await get(ServiceCategoryRoutes.getServiceCategories);
    return data;
  },
  getServiceCategoryByID: async (id) => {
    const data = await get(ServiceCategoryRoutes.getServiceCategoryByID+`${id}`);
    return data;
  },
  getServiceByCategoryAndAircraftId: async (obj) => {
    const data = await get(ServiceCategoryRoutes.getServiceByCategoryAndAircraftId+`${obj?.categoryId}/${obj?.aircraftId}`);
    return data;
  },
  getServicesPriceByTime: async (obj) => {
    const data = await get(ServiceCategoryRoutes.getServicesPriceByTime+`?time=${obj?.time}&service_price_variations_ids=${obj.service_price_variations_ids}`);
    return data;
  },
}

export default ServiceCategoryServices