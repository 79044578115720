import { get, post, patch, deleted } from 'app/apis';
import ManageEngineersRoutes from './ManageEngineers.Routes';

const ManageEngineersServices = {
  getEngineers: async (obj) => {
    const data = await get(ManageEngineersRoutes.getEngineers+`?limit=${obj.limit}&page=${obj.page}`);
    return data;
  },
  getEngineerById: async (id) => {
    const data = await get(ManageEngineersRoutes.getEngineerById+`${id}`);
    return data;
  },
  postEngineer: async (obj) => {
    const data = await post(ManageEngineersRoutes.postEngineer,obj);
    return data;
  },
  patchEngineerById: async (obj) => {
    const data = await patch(ManageEngineersRoutes.patchEngineerById+`${obj.id}`,obj.data);
    return data;
  },
  patchEngineerStatus: async (obj) => {
    const data = await patch(ManageEngineersRoutes.patchEngineerStatus+`${obj.id}`,obj.data);
    return data;
  },
  deleteEngineerById: async (id) => {
    const data = await deleted(ManageEngineersRoutes.deleteEngineerById+`${id}`);
    return data;
  },
}

export default ManageEngineersServices