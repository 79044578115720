import React, { useState } from 'react'
import { Typography, Grid, Box, Tabs, Tab } from '@mui/material';

// Import Components
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import ProfileSettings from './ProfileSettings';
import AccountSettings from './AccountSettings';

function MyProfile() {
  const [settings, setSettings] = useState('profile');

  return (
    <Layout>
      <Grid container gap={2} alignItems={'flex-start'} 
        justifyContent={'center'} my={4} >
        <Grid item xs={12} md={12}>
        <Tabs value={settings} onChange={(e,v) => setSettings(v)} centered>
          <Tab label="My Profile" value={'profile'} />
          <Tab label="My Accounts" value={'accounts'} />
        </Tabs>
        </Grid>
        <Grid item xs={12} md={12} alignItems={'center'}>
          <Box sx={{display:'flex',alignItems:'center', justifyContent: 'center',mb: 2,flexDirection:'column'}}>
            <Typography variant="h4" sx={{ color: colors.primary, fontWeight:'bold',
            textTransform:'capitalize'}}>My {settings}</Typography>
          </Box>
          {settings === 'profile' ? <ProfileSettings /> : <AccountSettings />}
        </Grid>
      </Grid>
    </Layout>
  )
}

export default MyProfile