import {  Box,  FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react'
import { ArrowBackTwoTone, DeleteOutline, EditTwoTone } from '@mui/icons-material';
import { PrimaryButton, SecondaryButton } from 'app/components/Buttons/Button';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));

const AccountInformationSchema = Yup.object().shape({
  customer: Yup.string().required('Customer field is Required'),
  supervisor: Yup.string().required('Supervisor field is Required'),
  aircraft_type: Yup.string().required('Aircraft Type field is Required'),
  aircraft_engineer: Yup.string().required('Aircraft Engineer field is Required'),
  date: Yup.string().required('Date field is Required'),
  time: Yup.string().required('Time field is Required'),
  asset_registration_number: Yup.string().required('Asset Registration Number field is Required'),
  city: Yup.string().required('City field is Required'),
  aircraft_msn: Yup.string().required('Aircraft MSN field is Required'),
  station: Yup.string().required('Station field is Required'),
  // services:Yup.array().of(Yup.object().shape({
  //   service_category_id:Yup.string().required('Service Category Id field is Required'),
  //   services:Yup.array().of(Yup.object().shape({
  //     id: Yup.string().required('Service Field is Required')
  //   })).min(1, 'At-least One service is Required').required('Services is field is Required'),
  // }))
});

function AccountInformation() {
  const {id} = useParams();
  const navigate = useNavigate();
  const [allowEdit, setAllowEdit] = useState(id ? true : false);
  const [loading, setLoading] = useState(false)
  return (
    <Layout>
      <Formik
        initialValues={{
          name: 'Abdullah Kareem',
          aircraft_type:1,
          commercial_license:234564545,
          operation_license:12234662,
          country:'UAE',
          city:'Jeddah',
          street:12,
          building:2,
          main_office:9134256362413,
          contact:[
          {id: 'Admin',name:'Abdullah', phone_number: 54542543622,email:'abdullah@gmail.com'},
          {id: 'Legal',name:'Abbas', phone_number: 77232736726,email:'abbas@gmail.com'},
          {id: 'Procurement',name:'Kareem', phone_number: 42532362233,email:'kareem@gmail.com'},
          {id: 'Operation  ',name:'Aslam', phone_number: 25362536232,email:'aslam@gmail.com'}],
        }}
      validationSchema={AccountInformationSchema}
      onSubmit={values => {
         console.log(values);
        // handleSubmitJobOrder(values);
        }}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', my:2}}>
            <Box sx={{display:'flex', alignItems:'center', my:2}}>
              <ArrowBackTwoTone
                onClick={() => navigate(-1)}
                  sx={{
                    backgroundColor: colors.primaryLight,color:colors.primary,
                    borderRadius: "6px",cursor:'pointer',mr:2,
                    p: 0.75,
                  }}
                />
              <Typography variant='h4' color={colors.primary} my={1.5}>Account Information</Typography>
            </Box>
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />
          </Box>
          <Grid container xs={12} md={12} spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,
            boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
            <Grid item xs={12} md={12}>
            <Typography color={colors.primary} sx={{fontSize:20, fontWeight:600}}>Basic Information:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Name</FormLabel>
              <TextField disabled={!allowEdit} name="name" label="Name" size="medium" value={values?.name} onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} />
              {touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Type</FormLabel>
              <Select name="type"  size="medium"
                disabled={!allowEdit}  
                value={values?.aircraft_type ? values?.aircraft_type : 0}
                onChange={(e) => {setFieldValue(`type`, e?.target?.value)}}
                sx={{color:values?.type === '' ? colors.labelColor : ''}} >
                <MenuItem value={0} disabled sx={{display:'none'}}>
                  Select Type
                </MenuItem>
                <MenuItem value={1}>Type 1</MenuItem>
                <MenuItem value={2}>Type 2</MenuItem>
                <MenuItem value={3}>Type 3</MenuItem>
              </Select>
              {touched.type && errors.type && <CustomErrorBox>{errors.type}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Commercial License</FormLabel>
              <TextField disabled={!allowEdit} name="commercial_license" label="Commercial License" size="medium" 
              value={values?.commercial_license} onChange={(e) => {setFieldValue(`commercial_license`, e?.target?.value)}} />
              {touched.commercial_license && errors.commercial_license && <CustomErrorBox>{errors.commercial_license}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Operation License</FormLabel>
              <TextField disabled={!allowEdit} name="operation_license" label="Operation License" size="medium" 
              value={values?.operation_license} onChange={(e) => {setFieldValue(`operation_license`, e?.target?.value)}} />
              {touched.operation_license && errors.operation_license && <CustomErrorBox>{errors.operation_license}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
            <Typography color={colors.primary} sx={{fontSize:20, fontWeight:600, my:2}}>Corporate Address:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Country</FormLabel>
              <TextField disabled={!allowEdit} name="country" label="Country" size="medium" 
              value={values?.country} onChange={(e) => {setFieldValue(`country`, e?.target?.value)}} />
              {touched.country && errors.country && <CustomErrorBox>{errors.country}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>City</FormLabel>
              <TextField disabled={!allowEdit} name="city" label="City" size="medium"
              value={values?.city} onChange={(e) => {setFieldValue(`city`, e?.target?.value)}} />
              {touched.city && errors.city && <CustomErrorBox>{errors.city}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Street</FormLabel>
              <TextField disabled={!allowEdit} name="street" label="Street" size="medium"
              value={values?.street} onChange={(e) => {setFieldValue(`street`, e?.target?.value)}} />
              {touched.street && errors.street && <CustomErrorBox>{errors.street}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Building</FormLabel>
              <TextField disabled={!allowEdit} name="building" label="Building" size="medium"
              value={values?.building} onChange={(e) => {setFieldValue(`building`, e?.target?.value)}} />
              {touched.building && errors.building && <CustomErrorBox>{errors.building}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
            <Typography color={colors.primary} sx={{fontSize:20, fontWeight:600, my:2}}>Contact Details:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth >
              <FormLabel sx={{mb:1.5,color:colors.primary}}>Main Office</FormLabel>
              <TextField disabled={!allowEdit} name="phone_number" label="Phone Number" type='number' size="medium"
              value={values?.main_office} onChange={(e) => {setFieldValue(`phone_number`, e?.target?.value)}} />
              {touched.phone_number && errors.phone_number && <CustomErrorBox>{errors.phone_number}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
            <FieldArray
                name="contact"
                render={arrayHelpers => (
                  <Grid item container>
                    {/* <Grid item xs={12} md={12} textAlign={'right'}>
                    {values.contact.length > 0 && 
                      <Button
                        disabled={!allowEdit}
                        type="button" variant='outlined'
                        onClick={() => arrayHelpers.push({id: '',name:'', phone_number: '',email:''})}
                        endIcon={<Add />}
                        sx={{textTransform:'capitalize'}}
                      >Add
                      </Button>}
                    </Grid> */}
                    {values.contact?.map((item, index) => (
                    <Grid item container spacing={2} key={index} alignItems={'flex-start'} my={0.25}>
                      <Grid item xs={12} md={2.4}>
                      {index < 4 ? (
                        <Typography textTransform={'capitalize'} p={0} fontSize={18} mt={{md:4}} justifyContent={'center'}>{item?.id}{' '}:</Typography>
                      ) : (
                        <FormControl fullWidth>
                          <FormLabel sx={{my:1, color:colors.primary}}>Type</FormLabel>
                          <TextField disabled={!allowEdit} name={`contact[${index}].id`} label="Type" size="medium" 
                          value={values?.contact[index]?.id}
                          onChange={(e) => {setFieldValue(`contact[${index}].id`, e?.target?.value)}} />
                          <ErrorMessage
                            name={`contact[${index}].id`}
                            render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                          />
                        </FormControl>
                        )}
                      </Grid>
                      <Grid item xs={12} md={3}>
                      <FormControl fullWidth>
                          <FormLabel sx={{my:1, color:colors.primary}}>Name</FormLabel>
                          <TextField disabled={!allowEdit} name={`contact[${index}].name`} label="Name" size="medium"
                          value={values?.contact[index]?.name} onChange={(e) => {setFieldValue(`contact[${index}].name`, e?.target?.value)}} />
                          <ErrorMessage
                            name={`contact[${index}].name`}
                            render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                      <FormControl fullWidth>
                          <FormLabel sx={{my:1, color:colors.primary}}>Mobile Number</FormLabel>
                          <TextField disabled={!allowEdit} name={`contact[${index}].mobile_number`} type='number' label="Mobile Number"
                          value={values?.contact[index]?.phone_number} size="medium" onChange={(e) => {setFieldValue(`contact[${index}].mobile_number`, e?.target?.value)}} />
                          <ErrorMessage
                            name={`contact[${index}].mobile_number`}
                            render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                      <FormControl fullWidth>
                        <FormLabel sx={{my:1, color:colors.primary}}>Email</FormLabel>
                        <TextField disabled={!allowEdit} name={`contact[${index}].email`} label="Email" size="medium"
                        value={values?.contact[index]?.email} onChange={(e) => {setFieldValue(`contact[${index}].email`, e?.target?.value)}} />
                        <ErrorMessage
                          name={`contact[${index}].email`}
                          render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                        />
                        </FormControl>
                      </Grid>
                      {index > 3 && 
                      <Grid item xs={12} md={0.5} textAlign={'right'}>
                      <Box disabled={!allowEdit} type="button" onClick={() => {arrayHelpers.remove(index);
                      }} sx={{backgroundColor: colors.dawnPink, width:'50px', height:'40px', borderRadius: '5px', display:'flex', alignItems:"center", justifyContent:'center', mt:4, cursor:'pointer'}}>
                        <DeleteOutline sx={{color:colors.danger}} />
                      </Box>
                      </Grid>}
                    </Grid>
                    ))}
                  </Grid>
                )}
              />
            </Grid>
            {allowEdit && 
            <Grid item xs={12} md={12} textAlign={'right'}>
              <SecondaryButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <PrimaryButton title={"Update"} type="submit" loading={loading} sxStyle={{ml:2}}/>
            </Grid>}
          </Grid>
        </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default AccountInformation