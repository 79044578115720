import Invoices from "app/views/Invoices/Invoices";
import SingleInvoice from '../views/Invoices/SingleInvoice';
import ManageFleetListing from "app/views/ManageFleet/ManageFleetListing";
import AccountInformation from "app/views/AccountInformation/AccountInformation";
import CreateFleet from "app/views/ManageFleet/CreateFleet";
import EngineersListing from "app/views/ManageEngineers/EngineersListing";
import MyProfile from "app/views/MyProfile/MyProfile";
import CreateListing from "app/views/JobOrder/CreateListing";
import ViewListing from "app/views/JobOrder/ViewListing";
import ViewJobOrder from "app/views/JobOrder/ViewJobOrder";
import CreatejobOrder from "app/views/JobOrder/CreateJobOrder";
import ViewEngineer from "app/views/ManageEngineers/ViewEngineer";


const AdminRoutes = [
  {
    path: "/create-job-order",
    component: <CreatejobOrder />,
  },
  {
    path: "/view-job-order",
    component: <ViewListing />,
  },
  {
    path: "/view-job-order/status/:status",
    component: <ViewListing />,
  },
  {
    path: "/view-job-order/:id",
    component: <ViewJobOrder />,
  },
  {
    path: "/edit-job-order/:order_number/:id",
    component: <CreatejobOrder />,
  },
  {
    path: "/view-invoices",
    component: <Invoices />,
  },
  {
    path: "/view-job-order/:id/:invoiceNumber",
    component: <SingleInvoice />,
  },
  {
    path: "/view-invoices/:id/:invoiceNumber",
    component: <SingleInvoice />,
  },
  {
    path:'/view-fleet',
    component: <ManageFleetListing/>
  },
  {
    path:'/view-fleet/add',
    component: <CreateFleet />
  },
  {
    path:'/view-fleet/:name/:id',
    component: <CreateFleet />
  },
  {
    path: "/account-information",
    component: <AccountInformation />,
  },
  {
    path:'/view-engineers/*',
    component: <EngineersListing />
  },
  {
    path:'/view-engineers/:id/:name',
    component : <ViewEngineer />
  },
  {
    path: "/my-profile",
    component: <MyProfile />,
  },
];

export default AdminRoutes