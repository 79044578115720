import { get, post, patch, deleted } from 'app/apis';
import ManageFleetRoutes from './ManageFleet.Routes';

const ManageFleetServices = {
  getFleet: async (obj) => {
    const data = await get(ManageFleetRoutes.getFleet+`?limit=${obj.limit}&page=${obj.page}`);
    return data;
  },
  getFleetById: async (id) => {
    const data = await get(ManageFleetRoutes.getFleetById+`${id}`);
    return data;
  },
  postFleet: async (obj) => {
    const data = await post(ManageFleetRoutes.postFleet,obj);
    return data;
  },
  patchFleetById: async (obj) => {
    const data = await patch(ManageFleetRoutes.patchFleetById+`${obj.id}`,obj.data);
    return data;
  },
  deleteFleetById: async (id) => {
    const data = await deleted(ManageFleetRoutes.deleteFleetById+`${id}`);
    return data;
  },
  getJobOrderListByAircraftId: async (obj) => {
    const data = await get(ManageFleetRoutes.getJobOrderListByAircraftId+obj?.id+`?limit=${obj.limit}&page=${obj.page}`);
    return data;
  },
  patchFleetStatus: async (obj) => {
    const data = await patch(ManageFleetRoutes.patchFleetStatus+`${obj.id}`,obj.data);
    return data;
  },
}

export default ManageFleetServices