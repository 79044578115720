import React, { Fragment, useState } from 'react'
import { Grid, InputAdornment, IconButton, FormControl, FormLabel, TextField, styled, } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, } from 'react-router-dom';
import * as Yup from 'yup';

// Import Components
import colors from 'app/styles/colors';
import MyProfileServices from 'app/apis/MyProfile/MyProfileServices';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { getCookie } from 'app/hooks/useCookie';
import { Form, Formik } from 'formik';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import useAuth from 'app/hooks/useAuth';

const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));

function ProfileSettings() {
  const userInfo = getCookie('userinfo');
  const { name,email } = userInfo

  const { login } = useAuth();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // *For Show Password Toggle Icon
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Profile Schema
  const ProfileSchema = Yup.object().shape({
    is_password:Yup.boolean(),
    password: Yup.string().when(['is_password'],(e) => {
      if(e[0] === true){
        return Yup.string().min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a special character').required('Password is required')}
      return Yup.string().notRequired();}), 
    password_confirmation: Yup.string().when(['is_password'],(e) => {
      if(e[0] === true){
        return Yup.string().oneOf([Yup.ref('password'), ''], 'Please provide the same password as above')
        .min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a special character').required('Confirm Password is required')}
      return Yup.string().notRequired();}), 
  });

  // check password
  const handleCheckPassword = (value,setValue) => {
    if(value){return setValue('is_password',true)}
    return setValue('is_password',false)
  }

  // *Update Profile
  const updateProfile = async (data) => {
    delete data?.is_password
    setLoading(true)
    try {
      const { status, responseCode, message } = await MyProfileServices.updateProfile(data)
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate('/');
        const updateSessionValues = {...userInfo,name:data?.name,email:data?.email}
        login(updateSessionValues)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };
  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          name: name ? name : '',
          email: email ? email : '',
          password :'',
          password_confirmation: '',
        }}
        validationSchema={ProfileSchema}
        onSubmit={values => {updateProfile(values)}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Grid container spacing={2} sx={{backgroundColor:colors.white, borderRadius: '10px',p:4,boxSizing:'border-box',
            boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',my:2}}>
            <Grid item xs={12} md={6} my={1}>
              <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Name</FormLabel>
                <TextField name="name" placeholder='Enter your Name' size="medium" value={values?.name}
                  onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} sx={{backgroundColor:'white'}}
                />
                {touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} my={1}>
              <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Email</FormLabel>
                <TextField name="email" type='email' placeholder='Enter your Email' size="medium" value={values?.email}
                  onChange={(e) => {setFieldValue(`email`, e?.target?.value)}} sx={{backgroundColor:'white'}}
                />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} my={1}>
              <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Password</FormLabel>
                <TextField name="password" placeholder='Enter your Password' size="medium" value={values?.password}
                  onChange={(e) => {setFieldValue(`password`, e?.target?.value);handleCheckPassword(e?.target?.value,setFieldValue)}} 
                  sx={{backgroundColor:'white'}}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment:(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        sx={{ color: colors.springGreen, fontSize: "18px" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    ),
                  }}
                />
                { errors.password && <CustomErrorBox>{errors.password}</CustomErrorBox>}
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6} my={1}>
              <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Confirmation Password</FormLabel>
                <TextField name="password_confirmation" placeholder='Confirm your Password' size="medium" value={values?.password_confirmation}
                  onChange={(e) => {setFieldValue(`password_confirmation`, e?.target?.value);;handleCheckPassword(e?.target?.value,setFieldValue)}} sx={{backgroundColor:'white'}}
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment:(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        edge="end"
                        sx={{ color: colors.springGreen, fontSize: "18px" }}
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    ),
                  }}
                />
                {errors.password_confirmation && <CustomErrorBox>{errors.password_confirmation}</CustomErrorBox>}
                </FormControl>
            </Grid>
            <Grid item xs={12} md={12} my={2} sx={{display:'flex',justifyContent:'end',gap:2}}>
              <CancelButton title={"Cancel"}  onClick={() => navigate('/')} />
              <CreateButton title={ "Update" } type="submit" loading={loading} />
            </Grid>
          </Grid>
        </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default ProfileSettings