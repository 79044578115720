import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import { ToasterContainer } from './components/Toaster/Toaster';
import useAuth from './hooks/useAuth';
import Login from './views/Auth/Login';
import AppRoutes from './routes/routes';
import AdminRoutes from './routes/admin.routes';
// import ConditionalRoutes from './routes/conditional.routes';
import colors from './styles/colors';
import ResetPassword from './views/Auth/ResetPassword';
import { KeyboardArrowDown } from '@mui/icons-material';
import ForgotPassword from './views/Auth/ForgotPassword';
import SessionLogout from './SessionLogout';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: colors.secondary,
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Roboto-300','Roboto-400','Roboto-500','Roboto-700',
      'sans-serif',
    ].join(','),

  },
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '200px', // Set your desired dropdown height here
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'white', // Change to your desired option background color
          '&:hover': {
            color:'white',
            backgroundColor: colors.springGreen, // Change to your desired hover background color
          },
          '&.Mui-selected': {
            color:'white',
            backgroundColor: colors.springGreen, // Change to your desired selected background color
            '&:hover': {
              color:'white',
              backgroundColor: colors.springGreen, // Change to your desired hover background color
            }
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option': {
            '&:hover': {
              color: 'white',
              backgroundColor: colors.springGreen, // Change to your desired hover background color
            },
          },
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            color: 'white',
            backgroundColor: colors.springGreen, // Change to your desired selected item background color
            '&:hover': {
              color:'black',
              backgroundColor: 'rgba(0, 128, 53,0.15)', // Change to your desired hover background color
            }
          },
        },
      },
      defaultProps: {
        endIcon: <KeyboardArrowDown />,
      },
    },
    MuiSelect:{
      styleOverrides:{
        menu: {
          maxHeight: '200px', // Adjust the maxHeight as needed
        },
      },
      defaultProps: {
        IconComponent: KeyboardArrowDown , // Set the custom icon globally
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: colors.springGreen, // Change to your desired chip background color
          color: 'white', // Change to your desired chip text color
        },
        deleteIcon: {
          color: 'white', // Change to your desired delete icon color
        },
      },
    },
  },
});

function App() {
  const { user, verify  } = useAuth();
  const role = 'admin'

  useEffect(() => {
    verify();
  })

  return (
    <BrowserRouter>

      <ToasterContainer />
      <SessionLogout>
        <ThemeProvider theme={theme}>

          <Routes>

            <Route element={!user ? <Outlet /> : <Navigate to="/" />} >
              <Route path="/login" element={<Login />} />
              <Route path="/reset-password/*" element={<ResetPassword />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
            </Route>

            {/* ========== App Routes ========== */}
            <Route element={user ? <Outlet /> : <Navigate to="/login" />} >

              {AppRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={route.component}
                />
              ))}

              {/* ========== Admin Routes ========== */}
              {role === "admin" &&
                AdminRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.component}
                  />
                ))
              }
            </Route>

          </Routes>

        </ThemeProvider>
      </SessionLogout>
    </BrowserRouter>
  );
}

export default App;
