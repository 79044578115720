import {  ConnectingAirports, BusinessCenterOutlined,  Receipt, Engineering, WorkOutline } from "@mui/icons-material";
import { BsSpeedometer2 } from 'react-icons/bs';

const Navigation = [
  {
    label: 'Dashboard',
    icon: <BsSpeedometer2 />,
    type: "",
    path: '/',
  },
  {
    label: 'Manage Fleet',
    icon: <ConnectingAirports />,
    type: "",
    path: '/view-fleet',
  },
  {
    label: 'Job Orders',
    icon: <WorkOutline />,
    type: "",
    path: '/view-job-order',
  },
  {
    label: 'Invoices',
    icon: <Receipt />,
    type: "",
    path: '/view-invoices',
  },
  {
    label: 'Manage Engineers',
    icon: <Engineering />,
    type: "",
    path: '/view-engineers',
  },
]
export default Navigation