import { get,post } from 'app/apis';
import AccountsRoutes from './Accounts.Routes';

const AccountsServices = {
  getCustomerTypes: async (obj) => {
    const data = await get(AccountsRoutes.getCustomerTypes);
    return data;
  },
  getMyInfo: async () => {
    const data = await get(AccountsRoutes.getMyInfo);
    return data;
  },
  postMyInfo: async (obj) => {
    const data = await post(AccountsRoutes.postMyInfo,obj);
    return data;
  },
  // Cities & Countries
  getCountries: async () => {
    const data = await get(AccountsRoutes.getCountries);
    return data;
  },
  getCitiesByCountryId: async (obj) => {
    const data = await get(AccountsRoutes.getCitiesByCountryId+`${obj.id}?limit=10${obj.query ? obj?.query : '&page=1'}`);
    return data;
  },
}

export default AccountsServices