import React,{useState,useEffect, Fragment} from 'react'
import { Autocomplete, Box, Button, Checkbox, FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Formik, Form,  FieldArray,ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Layout from 'app/layout/Layout'
import colors from 'app/styles/colors';
import {Add,DeleteOutline, EditTwoTone} from '@mui/icons-material/';
// import Images from 'app/assets/Images';
import ServiceCategoryServices from '../../apis/ServiceCategory/ServiceCategoryServices';
import { SecondaryButton } from 'app/components/Buttons/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import JobOrderServices from 'app/apis/JobOrder/JobOrderService';
import styled from '@emotion/styled';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import AircraftServices from 'app/apis/Aircraft/AircraftServices';
import ManageEngineersServices from 'app/apis/ManageEngineers/ManageEngineersServices';
import SupervisorServices from 'app/apis/Supervisors/SupervisorServices';
import ManageFleetServices from 'app/apis/ManageFleet/ManageFleetService';
import useAuth from 'app/hooks/useAuth';
import dayjs from 'dayjs';
import { AutocompleteInput } from 'app/components/Input/Autocomplete';
import Loader from 'app/components/Loader/Loader';

const CreateListingSchema = Yup.object().shape({
  aircraft_id: Yup.number().required('Aircraft Type field is Required'),
  aircraft_engineer_id: Yup.number().required('Aircraft Engineer field is Required'),
  date: Yup.string().required('Date field is Required'),
  time: Yup.string().required('Time field is Required'),
  station_id:Yup.number().required('Station field is Required'),
  apron:Yup.string().required('Apron field is Required'),
  services:Yup.array().of(Yup.object().shape({
    service_category_id: Yup.number().required('Service Category field is Required'),
    services: Yup.array().of(Yup.object().shape({
      service_id: Yup.number().required('Service is Required'),
      })).min(1,'At leas One Service is Required'),
    })),
});  

const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));


function CreateListing() {
  const { name } = useAuth();
  
  const {id='',order_number=''} = useParams();
  const navigate = useNavigate();
  const [allowEdit, setAllowEdit] = useState(id ? false : true);
  const [loading, setLoading] = useState(false);
  // loader
  const [isLoading, setIsLoading] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [serviceDataOptions, setServiceDataOptions] = useState([]);
  const [jobOrderDetails, setJobOrderDetails] = useState('');
  const [stations, setStations] = useState([]);
  const [aircraft, setAircraft] = useState([]);
  const [aircraftEngineers, setAircraftEngineers] = useState([]);
  const [aircraftDetails, setAircraftDetails] = useState({tail_number:'',msn_number:''})
  // Pricing Details
  const [subTotal, setSubTotal] = useState(0);
  const [VAT, setVAT] = useState(0);

  const handleAircraftDetails = (value) =>{
    if(value){
      const details = aircraft?.find((e) => e?.aircraft_served_id === value)
      setAircraftDetails({tail_number:details?.tail_number,msn_number:details?.msn_number})
    }
  }

  const handleCategory = (value, index) => {
    setServiceDataOptions(prevArray => {
      if (index >= prevArray?.length) {
        const newArray = [...prevArray];
        newArray.length = index + 1;
        const updatedArray = [...newArray];
        updatedArray[index] = value;
        return updatedArray;
      }
      else{
        const updatedArray = [...prevArray];
        updatedArray[index] = value;
        return updatedArray
      }
    });
  }

  // handle Total Summary Details
  const handleTotals = (value) => {
    let price = 0
    for (let serviceIndex = 0; serviceIndex < value?.length; serviceIndex++) {
      for (let servicePrice = 0; servicePrice < value[serviceIndex]?.services.length; servicePrice++) {
        price = +price + value[serviceIndex]?.services[servicePrice]?.price
      }      
    }
    setSubTotal(price);
  } 

  // Submit Create Order
  const handleSubmitJobOrder = async (obj) => {
    try {
      const newData = {...obj, services: JSON.stringify(obj.services)}
      setLoading(true)
      if(id){
        const { status, responseCode, message } = await JobOrderServices.updateJobOrder(id,newData)
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        }
      } else {
        const { status, responseCode, message } = await JobOrderServices.postJobOrderDetails(newData)
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
      }
    }
    navigate('/view-job-order')
    setLoading(false)
    } catch (error) {
      setLoading(false)
      ErrorToaster(error)
    }
  }

  // *For Getting Stations
  const getStations = async () => {
    try {
      const { status, responseCode, data } = await AircraftServices.getStations()
      if (status === true && responseCode === 200) {
        setStations(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Service Category
  const getServicesCategory = async () => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceCategories({limit:10000,page:1,status:1})
      if (status === true && responseCode === 200) {
        setServiceCategories(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // For Getting  VAT
  const getVAT = async () => {
    try {
      const { status, responseCode, data } = await JobOrderServices.getVAT()
      if (status === true && responseCode === 200) {
        setVAT(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Aircraft
  const getFleet = async () => {
    try {
      const { status, responseCode, data} = await ManageFleetServices.getFleet({limit:1000,page:1})
      if (status === true && responseCode === 200) {
        setAircraft(data)
        return data
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Aircraft engineers
  const getEngineers = async () => {
    try {
      const { status, responseCode, data} = await ManageEngineersServices.getEngineers({limit:1000,page:1,status:1})
      if (status === true && responseCode === 200) {
          setAircraftEngineers(data);
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Services By Category Id And Aircraft Id
  const getServiceByCategoryAndAircraftId = async (obj,indexValue) => {
    try {
      const { status, responseCode, data} = await ServiceCategoryServices.getServiceByCategoryAndAircraftId(obj)
      if (status === true && responseCode === 200) {
        handleCategory(data,indexValue)
      }
    } catch (error) {
      handleCategory([],indexValue);
      ErrorToaster(error)
    }
  }

  // *For Getting Services Updated Price By Time
  const getServicesPriceByTime = async (values,setFieldValue) => {
    try {          
      if(values?.services[0]?.services[0]?.service_id !== '' && values?.time){
        const price_variation_ids = []
        for (let serviceIndex = 0; serviceIndex < values?.services?.length; serviceIndex++) {
          for (let serviceVariationIndex = 0; serviceVariationIndex < values?.services[serviceIndex]?.services?.length; serviceVariationIndex++) {
            if(values?.services[serviceIndex]?.services[serviceVariationIndex]?.service_price_variation_id){
              price_variation_ids?.push(values?.services[serviceIndex]?.services[serviceVariationIndex]?.service_price_variation_id)
            }
          }
        }
      if(price_variation_ids?.length > 0){
        const { status, responseCode, data} = await ServiceCategoryServices.getServicesPriceByTime({time:values?.time,service_price_variations_ids:price_variation_ids})
        if (status === true && responseCode === 200) {
          let newServiceValues = [...values?.services]
          let newOptions = [...serviceDataOptions]
          for (let dataIndex = 0; dataIndex < values?.services?.length; dataIndex++) {
            // updating set values
            const updatedPriceValue = values?.services[dataIndex]?.services?.map((sourceItem) => {
              const matchingItem = data?.find((updateItem) => updateItem?.id === sourceItem?.id);
              
              if (matchingItem) {
                // Merge the sourceItem and matchingItem to update values
                return { ...sourceItem, ...matchingItem };
              }
          
              return sourceItem; // No match found, return the original item
            });
            newServiceValues[dataIndex].services = updatedPriceValue  // --> For updating value price without clearing value
            // newServiceValues[dataIndex].services = [] // --> For Emptying the services value
            
            // updating options with updated price
            const updatedOptionsWithPrice = serviceDataOptions[dataIndex]?.map((sourceOptionItem) => {
              const matchingOptionItem = data?.find((updateOptionItem) => updateOptionItem?.service_id === sourceOptionItem?.service_id);
              
              if (matchingOptionItem) {
                // Merge the sourceItem and matchingItem to update values
                return { ...sourceOptionItem, ...matchingOptionItem };
              }
          
              return sourceOptionItem || []; // No match found, return the original item
            });
            newOptions[dataIndex] = [...updatedOptionsWithPrice]
          }
          setFieldValue(`services`,newServiceValues);
          setServiceDataOptions(newOptions);
        }
      }
    }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For getting services by changing aircraft 
  const getServicesByAircraftAndTime = (values,setFieldValue) => {
    if(values?.time && values?.aircraft_id){
      for (let servicesIndex = 0; servicesIndex < values?.services?.length; servicesIndex++) {
        setFieldValue(`services[${servicesIndex}]`,{service_category_id: '',service_category_name:'',services:[]});
        setServiceDataOptions([])
        // getServiceByCategoryAndAircraftId({categoryId:values?.services[servicesIndex]?.service_category_id,
        //   aircraftId:values?.aircraft_id,time:values?.time},servicesIndex).then((res) => {
        //     console.log('res',res)
        //     const newServiceValue = [...values?.services]
        //     console.log("🚀 ~ file: CreateListing.js:273 ~ getServicesByAircraftAndTime ~ newServiceValue:", newServiceValue)
            // if(res?.length <=0){
            //   newServiceValue?.splice(servicesIndex,1);
            //   console.log("🚀 ~ file: CreateListing.js:276 ~ getServicesByAircraftAndTime ~ newServiceValue:", newServiceValue)
            // } else {
            //   setFieldValue(`services[${servicesIndex}].services`, [])
            // }
          // })
      }
    }
  }

  // getting job order by ID
  const getJobOrderForEditById = async (id) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data} = await JobOrderServices.getJobOrderForEditById(id)
      if (status === true && responseCode === 200) {
        setJobOrderDetails(data);
        setIsLoading(false);
      }
      return data
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getFleet();
    getEngineers()
    getServicesCategory();
    getStations();
    getVAT();
    if(id){
      getJobOrderForEditById(id).then((res) => {
        if(res){
          getFleet().then((fleetRes) => {
            if(fleetRes && fleetRes?.length > 0){
              const details = fleetRes?.find((e) => e?.aircraft_served_id === res?.aircraft_id)
              setAircraftDetails(() => {return {tail_number:details?.tail_number,msn_number:details?.msn_number}})
            }
          });
          for (let jobDetailsIndex = 0; jobDetailsIndex < res?.services?.length; jobDetailsIndex++) {
            getServiceByCategoryAndAircraftId({categoryId:res?.services[jobDetailsIndex]?.service_category_id,aircraftId:res?.aircraft_id,time:res?.time},jobDetailsIndex)
          }
        }
      })}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id]);

  return (
    <Layout>
      <Formik
        enableReinitialize
        initialValues={{
          aircraft_id:id ? jobOrderDetails?.aircraft_id : '',
          aircraft_engineer_id:id ? jobOrderDetails?.aircraft_engineer_id : '',
          date:id ? jobOrderDetails?.date : '',
          time:id ? jobOrderDetails?.time : '',
          apron:id ? jobOrderDetails?.apron :'',
          station_id:id ? jobOrderDetails?.station_id : '',
          services:id ? jobOrderDetails?.services : [{service_category_id: '',service_category_name:'',services:[]}],
        }}
       validationSchema={CreateListingSchema}
       onSubmit={values => {
         handleSubmitJobOrder(values);
       }}
     >
       {({ errors, touched, values,setFieldValue }) => (
         <Form>
         {handleTotals(values?.services)}
         <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <ArrowBackButton />
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>{id ? `${allowEdit ? 'Edit' : ''} Job Order # ${order_number}` : 'Create Job Order'}</Typography>
            </Box>
            {id && 
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />}
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Job Orders',!id ? 'Create Job Order' : `${allowEdit ? 'Edit' : ''} Job Order`]} />
          </Box>
          {isLoading ? <Loader /> : 
          <Fragment>
            <Grid container xs={12} md={12} spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,
                boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',my:2}}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Customer</FormLabel>
                  <TextField disabled name="customer" value={name} placeholder="Customer Name" size="lg" />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Station</FormLabel>
                  <Select name="station_id"  size="medium"
                    disabled={!allowEdit}  
                    value={values?.station_id ? values?.station_id : 0}
                    onChange={(e) => {setFieldValue(`station_id`, e?.target?.value)}}
                    sx={{color:values?.station_id === '' ? colors.labelColor : ''}} >
                    <MenuItem value={0} disabled sx={{display:'none'}}>
                      Select Station
                    </MenuItem>
                    {stations?.length > 0 ? stations?.map((stationItem,index) => (
                    <MenuItem key={index} value={stationItem?.id}>{stationItem?.name}</MenuItem>
                  )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                  </Select>
                  {touched.station_id && errors.station_id && <CustomErrorBox>{errors.station_id}</CustomErrorBox>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth >
                  <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Aircraft</FormLabel>
                  <Select name="aircraft_id"  size="medium"
                    disabled={!allowEdit}  
                    value={values?.aircraft_id ? values?.aircraft_id : 0}
                    onChange={(e) => {setFieldValue(`aircraft_id`, e?.target?.value);handleAircraftDetails(e?.target?.value);
                    getServicesByAircraftAndTime(values,setFieldValue)}}
                    sx={{color:values?.aircraft_id === '' ? colors.labelColor : ''}} >
                    <MenuItem value={0} disabled sx={{display:'none'}}>
                      Select Aircraft
                    </MenuItem>
                    {aircraft?.length > 0 ? aircraft?.map((aircraftItem,index) => (
                    <MenuItem key={index} value={aircraftItem?.aircraft_served_id}>{aircraftItem?.aircraft_served_name}</MenuItem>
                  )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                  </Select>
                  {touched.aircraft_id && errors.aircraft_id && <CustomErrorBox>{errors.aircraft_id}</CustomErrorBox>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Job Order Start Date</FormLabel>
                  <TextField type="date" disabled={!allowEdit} name="date" min={'2023-06-05'} placeholder="Job Order Start Date" size="lg" 
                    value={values?.date} onChange={(e) => {setFieldValue(`date`, e?.target?.value)}}/>
                    {touched.date && errors.date && <CustomErrorBox>{errors.date}</CustomErrorBox>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Job Order Start Time</FormLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}  >
                    <MobileTimePicker disabled={!allowEdit} name="time" placeholder="Job Order Start Time"
                    value={values?.date && values?.time ? dayjs(`${values?.date}T${values?.time}`) : null} 
                    onChange={(e) => {setFieldValue(`time`, (e?.$d)?.toString()?.split(' ')[4]);
                    getServicesPriceByTime(values,setFieldValue)}}/>
                  </LocalizationProvider>
                  {touched.time && errors.time && <CustomErrorBox>{errors.time}</CustomErrorBox>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Aircraft Tail Number</FormLabel>
                  <TextField disabled={true} name="aircraft_tail_number" label="Asset Registration Number" size="medium" value={aircraftDetails?.tail_number}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Aircraft MSN</FormLabel>
                  <TextField disabled={true} name="aircraft_msn" label="Aircraft MSN" size="medium" value={aircraftDetails?.msn_number}
                  />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5,  color:colors.formLabel, fontWeight:'bold'}}>Aircraft Engineer</FormLabel>
                  <Select name="aircraft_engineer_id"  size="medium"
                    disabled={!allowEdit }  
                    value={values?.aircraft_engineer_id ? values?.aircraft_engineer_id : 0}
                    onChange={(e) => {setFieldValue(`aircraft_engineer_id`, e?.target?.value)}}
                    sx={{color:values?.aircraft_engineer_id === '' ? colors.labelColor : ''}} >
                    <MenuItem value={0} disabled sx={{display:'none'}}>
                      Select Aircraft Engineer
                    </MenuItem>
                    {aircraftEngineers?.length > 0 ? aircraftEngineers?.map((aircraftEngineerItem,index) => (
                    <MenuItem key={index} value={aircraftEngineerItem?.id}>{aircraftEngineerItem?.aircraft_engineer_name}</MenuItem>
                  )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                  </Select>
                  {touched.aircraft_engineer_id && errors.aircraft_engineer_id && <CustomErrorBox>{errors.aircraft_engineer_id}</CustomErrorBox>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Apron</FormLabel>
                  <TextField disabled={!allowEdit} name="apron" label="Apron" size="medium" onChange={(e) => {setFieldValue(`apron`, e?.target?.value)}} />
                  {touched.apron && errors.apron && <CustomErrorBox>{errors.apron}</CustomErrorBox>}
                  </FormControl>
                </Grid>
            </Grid>
            {/* Services Section */}
            <Grid container xs={12} md={12} spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:2,
              boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',my:2}}>
              <FieldArray
                name="services"
                render={arrayHelpers => (
                <Grid item container xs={12} md={12} >
                  <Grid item xs={12} md={12}>
                    <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                      <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'30px'}}>Choose Services:</Typography>
                      {values?.services?.length < serviceCategories?.length && 
                      <Button
                        type="button" variant='outlined' disabled={!allowEdit}
                        onClick={() => arrayHelpers.push({service_category_id: '',service_category_name:'',services:[]})}
                        startIcon={<Add />}
                        sx={{textTransform:'capitalize'}}>Add
                      </Button>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {values?.services?.map((_, index) => (
                      <Grid item container spacing={2}  key={index} alignItems={'flex-start'}>
                        <Grid item xs={12} md={values?.services?.length === 1 ? 6 : 5.70}>
                          <FormControl fullWidth size="small">
                            <FormLabel sx={{my:1,  color:colors.formLabel, fontWeight:'bold'}}>Service Category</FormLabel>
                            <Select
                              size='medium' disabled={!allowEdit || (!values?.aircraft_id || !values?.time)}
                              name={`services[${index}].service_category_id`}
                              value={values.services[index]?.service_category_id ? values.services[index]?.service_category_id : 0}
                              onChange={(e) => {setFieldValue(`services[${index}].service_category_id`, e?.target?.value);
                              setFieldValue(`services[${index}].service_category_name`,serviceCategories?.find((v) => v?.id === e?.target?.value)?.name);
                              setFieldValue(`services[${index}].services`, []);
                              getServiceByCategoryAndAircraftId({categoryId:e?.target?.value,aircraftId:values?.aircraft_id,time:values?.time},index)}}
                              sx={{color:values.services[index]?.service_category_id === '' ? colors.labelColor : ''}}
                            >
                            <MenuItem value={0} disabled sx={{display:'none'}}>
                              Select Service Category
                            </MenuItem>
                            {serviceCategories?.map((item,index) => (
                              <MenuItem key={index} value={item?.id} 
                              sx={{display: values?.services?.some((service) => service?.service_category_id === item?.id) ? 'none' : 'block'}}>{item?.name}</MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage
                              name={`services[${index}].service_category_id`}
                              render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={values?.services?.length === 1 ? 6 : 5.70}>
                        <FormControl fullWidth size="small">
                          <FormLabel sx={{ my:1, color:colors.formLabel, fontWeight:'bold'}}>Add Service</FormLabel>
                          <AutocompleteInput name={`services[${index}].services`} limitTag={1} identifier='service_name' identifier_2='price'
                            disabled={!allowEdit ? !allowEdit : values?.services[index]?.service_category_id === ""} 
                            data={serviceDataOptions[index]?.length > 0 ? serviceDataOptions[index] : []}  
                            value={values?.services[index]?.services} placeholder={'Select Service'}
                            onChange={(e, value) => {setFieldValue(`services[${index}].services`,value)}} />
                          <ErrorMessage
                            name={`services[${index}].services`}
                            render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                          />
                        </FormControl>
                        </Grid>
                        {index !== 0 && 
                        <Grid item xs={12} md={0.5} >
                          <Box disabled={!allowEdit} type="button" onClick={() => {arrayHelpers.remove(index);
                          // setServiceCategory((prev) =>{
                          //   const value = [...prev]
                          //   if( index <= value?.length - 1){
                          //     value?.splice(index,1)
                          //     return value
                          //   }
                          //   else{
                          //     return value
                          //   }
                          // })
                          }} sx={{backgroundColor: colors.dawnPink, width:'55px', height:'55px', borderRadius: '5px', display:'flex', alignItems:"center", justifyContent:'center', mt:5, cursor:'pointer'}}>
                            <DeleteOutline sx={{color:colors.danger}} />
                          </Box>
                        </Grid>}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                )}
              />
              <Grid item xs={12} md={12} my={3}>
                <Box sx={{display:'flex', alignItems:'center', float:'right', backgroundColor:'#EEF1F6',width:'max-content',p:1.25,borderRadius:'8px'}}>
                  {/* Sub Total */}
                  <Box sx={{display:'flex', alignItems:'baseline',mx:2}}>
                    <Typography sx={{fontSize:16, fontWeight:'bold', color:'#717274',mr:2}}>Sub Total:</Typography>{' '}
                    <Typography sx={{fontSize:24, fontWeight:'bold', color:'#717274'}}>${subTotal}</Typography>
                  </Box>
                  {/* VAT */}
                  <Box sx={{display:'flex', alignItems:'baseline',mx:2}}>
                    <Typography sx={{fontSize:16, fontWeight:'bold', color:'#717274',mr:2}}>VAT:</Typography>{' '}
                    <Typography sx={{fontSize:24, fontWeight:'bold', color:'#717274'}}>${(+subTotal * (VAT/100))?.toFixed(2)}</Typography>
                  </Box>
                  {/* Total */}
                  <Box sx={{display:'flex', alignItems:'baseline',mx:2}}>
                    <Typography sx={{fontSize:16, fontWeight:'bold', color:'#717274',mr:2}}>Total:</Typography>{' '}
                    <Typography sx={{fontSize:24, fontWeight:'bold', color:'#717274'}}>${+(subTotal) + +(+subTotal*(VAT/100))?.toFixed(2)}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {allowEdit && 
            <Box textAlign={'right'}>
              <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxStyle={{ml:2}}/>
            </Box>}
          </Fragment>}
        </Form>)}
      </Formik>
    </Layout>
  )
}

export default CreateListing