import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Typography,  styled, tableCellClasses } from '@mui/material'
import colors from 'app/styles/colors';
import { numberSARFormat } from 'app/utils/helper';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'white',
      color: colors.secondaryDark,
      fontSize:18, fontWeight:600
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  

function ServicesTable({data,summary}) {
  return (
    <TableContainer component={Paper} sx={{my:2}}>
      <Table sx={{ minWidth: 700,"& .MuiTableCell-root": {
      border: '1px solid #ddd',
    } }} >
        <TableHead>
          <StyledTableCell align="left" colSpan={3} style={{backgroundColor:'#eee'}}>Service Summary</StyledTableCell>
          <TableRow>
            <StyledTableCell align="center">Category</StyledTableCell>
            <StyledTableCell align="center">Service</StyledTableCell>
            <StyledTableCell align="center">Price</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {data.map((row,index) => (
            <TableRow key={`${row.serviceCategory}-${index}`}>
            {row?.rowSpan > 0 && 
              <StyledTableCell align="center" rowSpan={row?.rowSpan} >{row.serviceCategory}</StyledTableCell>
              }
              <StyledTableCell align="center">{row.serviceName}</StyledTableCell>
              <StyledTableCell align="center">${numberSARFormat(row.servicePrice)}</StyledTableCell>
            </TableRow>
          ))}
          <TableRow >
            <TableCell align="right" colSpan={2}>
            <Typography sx={{fontWeight:600,fontSize:16}}>Sub Total</Typography></TableCell>
            <StyledTableCell align="center">${numberSARFormat(summary?.sub_total)}</StyledTableCell>
          </TableRow>
          <TableRow >
            <TableCell align="right" colSpan={2}>
            <Typography sx={{fontWeight:600,fontSize:16}}>VAT ({parseInt((summary?.vat / summary?.sub_total) * 100)}%)</Typography></TableCell>
            <StyledTableCell align="center">${numberSARFormat(summary?.vat)}</StyledTableCell>
          </TableRow>
          <TableRow >
            <TableCell align="right" colSpan={2}>
            <Typography sx={{fontWeight:600,fontSize:16}}>Total</Typography></TableCell>
            <StyledTableCell align="center">${numberSARFormat(summary?.total)}</StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ServicesTable