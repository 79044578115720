import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Grid, Box, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// Import Components
import useAuth from 'app/hooks/useAuth';
import AuthServices from 'app/apis/Auth/AuthServices';
import Images from 'app/assets/Images';
import colors from 'app/styles/colors';
import { emailRegex } from 'app/utils';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import { OutlineInputField } from 'app/components/UI/TextField';
import { PrimaryButton } from 'app/components/Buttons/Button';
import { useNavigate } from 'react-router-dom';

function Login() {

  const { login } = useAuth();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);

  // *For Show Password Toggle Icon
  const [showPassword, setShowPassword] = useState(false);

  // *Login 
  const loginInfo = async (formData) => {
    setLoading(true)
    try {
      let obj = {
        email: formData.email,
        password: formData.password,
        platFormType:3
      }
      const { status, responseCode, data } = await AuthServices.login(obj)
      if (status === true && responseCode === 200) {
        login(data)
        // SuccessToaster(message)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };

  return (
    <Grid container alignItems={'center'} justifyContent={'center'} 
    sx={{ height: "100vh", backgroundImage: `url(${Images.plane})`,backgroundSize: 'cover',
      backgroundPosition: 'center',zIndex:2 }}>
      <Grid item container xs={12} md={12} sx={{backgroundColor: `rgba(0, 126, 53, 0.5)`,height:'100%'}} alignItems={'center'} justifyContent={'center'}>
        <Grid item container xs={10} md={3.5} alignItems={'center'} 
          sx={{my:4,minHeight:'460px',borderRadius:10, backgroundColor:'rgba(90,153,123, 0.6)',backdropFilter: 'blur(27px)',boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.2)'}}>
          <Grid item xs={12} md={12} mx={3}>
            <Box sx={{ textAlign: "center",borderRadius: 2,width: '100%', boxSizing: "border-box",py:4,px:2}}>
              <Box sx={{display:'flex',alignItems:'center', justifyContent: 'center',my: 2,}}>
              <Typography variant="h4" sx={{ color: colors.white, fontWeight:'bold'}}>LOGIN</Typography>
              {/* <Typography variant="body1" sx={{ color: colors.primary,  cursor:'pointer' }}>Don't have an account?</Typography> */}
              </Box>
              <Typography component={"form"} onSubmit={handleSubmit(loginInfo)}>
                <Box sx={{ mt: 2, display: "flex", flexDirection: 'column' }}>
                <Typography variant='body1' sx={{color:'#FFFFFF', textAlign:'left', mb:2,fontWeight:'bold'}}>Email</Typography>
                  <OutlineInputField
                    label="Email"
                    type={'email'}
                    error={errors?.email?.message}
                    register={register("email", {
                      required: 'Please enter your email.',
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email address',
                      }
                    })}
                  />
                  <Typography variant='body1' sx={{color:'#FFFFFF', textAlign:'left', mb:2,fontWeight:'bold'}}>Password</Typography>
                  <OutlineInputField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          sx={{ color: colors.springGreen, fontSize: "18px" }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={errors?.password?.message}
                    register={register("password", {
                      required: 'Please enter password.',
                    })
                    }
                  />
                  <Typography onClick={() => navigate('/forgot-password')} variant='body1' sx={{color:colors.white, textAlign:'right',cursor:'pointer' ,mb:2}}>Forgot Password ?</Typography>
                </Box>
                <PrimaryButton
                  title="Login"
                  type='submit'
                  loading={loading}
                  fullWidth={true}
                  sxStyle={{backgroundColor:'#306E52',border:'2px solid white',py:1,borderRadius:'12px',
                  fontSize:18,fontWeight:'bold',"&:hover":{backgroundColor:'#306E52'}}}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Login;