import React from 'react';
import { LoadingButton } from '@mui/lab';
import { CancelOutlined, Create } from '@mui/icons-material';
// import colors from 'app/styles/colors';

export const CancelButton = (props) => {
  return (
    <LoadingButton
      variant="contained"
      {...props}
      startIcon={<CancelOutlined />}
      sx={{
        color:'white',
        fontWeight:'bold',borderRadius:'8px',
        textTransform: 'capitalize',
        minWidth: '120px',py:1,
        backgroundColor: '#DC4444',
        ...props.sxStyle,
        "&:hover": { bgcolor: '#DC4444' }
      }}
    >
      {props.title}
    </LoadingButton>
  );
}

export const CreateButton = (props) => {
  return (
    <LoadingButton
      variant="contained"
      {...props}
      startIcon={<Create />}
      sx={{
        color:'white',
        fontWeight:'bold',borderRadius:'8px',
        textTransform: 'capitalize',
        minWidth: '120px',py:1,
        backgroundColor: '#008035',
        ...props.sxStyle,
        "&:hover": { bgcolor: '#008035' }
      }}
    >
      {props.title}
    </LoadingButton>
  );
}