import { Fragment, useEffect, useState } from 'react'
import {  useNavigate, useParams } from 'react-router-dom';
// Marterial Import
import { Box, FormControl, FormLabel, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, styled, tableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { EditTwoTone } from '@mui/icons-material';
// Formik Import
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// Service Import
import ManageEngineersServices from 'app/apis/ManageEngineers/ManageEngineersServices';
import JobOrderServices from 'app/apis/JobOrder/JobOrderService';
// Component Import
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import Loader, { SmLoader } from 'app/components/Loader/Loader';
import StatusChip from 'app/components/StatusChip/StatusChip';
import { SecondaryButton } from 'app/components/Buttons/Button';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';


const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',
    color: '#3F424B',fontSize:16,fontWeight:'bold',textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center'
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id: 'order_number', label: 'Job Order Number'},
  {id: 'invoice_number', label: 'Invoice Number'},
  {id: 'supervisor_name',label: 'Operation Supervisor',align: 'left',},
  {id: 'aircraft_engineer_name',label: 'Aircraft Engineer',align: 'left',},
  {id: 'start_date',label: 'Date',align: 'left',},
  {id: 'order_status',label: 'Order Status',align: 'left',},
];

const CustomErrorBox = styled('div')(() => ({
    fontSize:'12px', color:colors.danger,margin:"8px 0px"
  }));

function ViewEngineer() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id,name } = useParams();

  // loader
  const [loading, setLoading] = useState(false);
  const [isEngineerJobOrderLoading, setIsEngineerJobOrderLoading] = useState(false);
  const [allowEdit, setAllowEdit] = useState(id ? false : true);
  
  // Pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // api data
  const [engineerData, setEngineerData] = useState({});
  const [jobOrderListByEngineerId, setJobOrderListByEngineerId] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  // *Validation Schema
  const engineerSchema = Yup.object().shape({
    name: Yup.string().required("Category Name is required"),
    email: Yup.string().required("Email is required"),
  });

  // JobOrderListByEngineerID
  const getJobOrderListByEngineertId = async (obj) => {
    try {
      setIsEngineerJobOrderLoading(true);
      const { status, responseCode, data,count } = await JobOrderServices.getAllJobOrderDetails(obj)
      if (status === true && responseCode === 200) {
        setJobOrderListByEngineerId(data);
        setCount(count);
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setIsEngineerJobOrderLoading(false);
  }
  // Getting Engineer Data for Update
  const getEngineerById = async (id) => {
    try {
      setLoading(true);
      const { status, responseCode, data } = await ManageEngineersServices.getEngineerById(id)
      if (status === true && responseCode === 200) {
        setEngineerData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false);
  }

  // handleSubmit
  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const { status, responseCode, message } = await ManageEngineersServices.patchEngineerById({id:id,data:{name:values?.name}})
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        setLoading(false)
      }
    } catch (error) {
      ErrorToaster(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getEngineerById(id);
  }, []);

  useEffect(() => {
    getJobOrderListByEngineertId({aircraft_engineer_id:id,limit:rowsPerPage,page:page})
  }, [id,rowsPerPage,page])
  

  return (
    <Layout>
    <Box>
    <Formik
        enableReinitialize
        initialValues={{
          name:id ? engineerData?.aircraft_engineer_name : '',
          email:id ? engineerData?.aircraft_engineer_email :'',
        }}
        validationSchema={engineerSchema}
        onSubmit={values => {handleSubmit(values)}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <ArrowBackButton />
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>{id ? `${allowEdit ? 'Edit' : ''} ${name}` : 'Engineer'}</Typography>
            </Box>
            {id && 
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />}
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Manage Engineer',`${allowEdit ? 'Edit' : 'View'} Engineer`]} />
          </Box>
          {loading ? <Loader /> :
          <Fragment>
            <Grid container xs={12} md={12} spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,my:2,
              boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormLabel sx={{my:1.5, color:colors.formLabel, fontWeight:'bold'}}>Name</FormLabel>
                  <TextField name="name" placeholder='Name' size="medium" value={values?.name} onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} />
                  {touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <FormLabel sx={{my:1.5, color:colors.formLabel, fontWeight:'bold'}}>Email</FormLabel>
                  <TextField name="email" placeholder='Email' size="medium" type='email' value={values?.email} disabled={true}/>
                  {touched.email && errors.email && <CustomErrorBox>{errors.email}</CustomErrorBox>}
                </FormControl>
              </Grid>
            </Grid>
            {allowEdit && 
            <Box textAlign={'right'}>
              <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxStyle={{ml:2}}/>
            </Box>}
            {/* Job Order Listing */}
            <Fragment>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'30px',my:2}}>Job Orders Assigned</Typography>
              </Box>
              {isEngineerJobOrderLoading ? <SmLoader /> : 
              <Fragment>
                {jobOrderListByEngineerId?.length > 0 ? (
                <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
                  <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table" >
                      <TableHead  >
                        <TableRow>
                          {columns.map((column) => {
                            if(column?.id=== 'invoice_number' && (filter === 'closed' || filter === 'all'))
                            {return (
                              <StyledTableCell key={column.id} align={column.align} style={{ fontWeight:600 }}>
                                {column.label}
                              </StyledTableCell>
                          )} else if(column?.id !== 'invoice_number'){
                            return(
                              <StyledTableCell key={column.id} align={column.align} style={{ fontWeight:600 }}>
                              {column.label}
                              </StyledTableCell>
                            )
                          }
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobOrderData?.map((row, index) => {
                          return(<TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <Fragment key={index}>
                                  {column.id === 'order_status' ? (
                                    <StyledTableCell key={column.id} >
                                    <StatusChip value={value?.toLowerCase() === 'open' ? 'Upcoming' : value} />
                                  </StyledTableCell>
                                  ) : column.id === 'invoice_number' ? (
                                    <Fragment>
                                    {(filter === 'closed' || filter === 'all') &&
                                      <StyledTableCell key={column.id}  sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                                      onClick={() =>  {column.clickAble && row?.invoice_id && 
                                      navigate(`/view-job-order/${row?.invoice_id}/${row?.invoice_number}`)}}>{row?.invoice_number ? row?.invoice_number : '--'}
                                      </StyledTableCell>}
                                    </Fragment>   
                                  ) : (
                                  <StyledTableCell key={column.id}  sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                                  onClick={() =>  {column.clickAble && navigate(`/view-job-order/${row.id}`)}}>
                                    {column.format && typeof value === 'number'
                                      ? column.format(value)
                                      : value}
                                  </StyledTableCell>)}
                                  </Fragment>
                                );
                              })}
                          </TableRow>)
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25,50 ,100]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page -1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>) : (<Box
                  display={'flex'}
                  p={6.25}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography variant="h5" fontWeight={400} >
                    No Job Order Assigned Yet
                  </Typography>
                </Box>)}
              </Fragment>}
            </Fragment>
          </Fragment>}
        </Form>
        )}
      </Formik>
    </Box>
    </Layout>
  )
}

export default ViewEngineer